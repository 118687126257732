<template>
    <PraePlanHeader :prae-plan-logo="true" :title="name">
    </PraePlanHeader>

    <main>
        <component :is="'Visualitza' + listTypes[type]" ref="visualitzaElement" @setName="setName">
        </component>    
    </main>
    <footer>
        <button class="PraePlanButton" @click="$refs.visualitzaElement.goBack()">&#60;</button>
        <button class="PraePlanButton" @click="$refs.visualitzaElement.goToEdit()">Edita</button>
    </footer>
</template>

<script>
import PraePlanHeader from '@/main_components/generic_components/PraePlanHeader.vue'
import VisualitzaMaterial from '@/main_components/materials_components/VisualitzaMaterial.vue'
import VisualitzaActivitat from '@/main_components/activitats_components/VisualitzaActivitat.vue'
import VisualitzaEntitat from '@/main_components/entitats_components/VisualitzaEntitat.vue'
import VisualitzaEmpleat from '@/main_components/empleats_components/VisualitzaEmpleat.vue'
import { ListType } from '@/main_components/generic_components/gloablTypes';


export default {
    data() {
        return {
            name: '',
            type: null,
            listTypes: ListType
        }
    },
    methods: {
        setName(name) {
            console.log("Setting Name.", name);
            this.name = name
        }
    },
    created() {
        this.type = this.$route.name.substr(10).toUpperCase()
    },
    mounted() {
        this.$refs.visualitzaElement.getElement()
    },
    components: {
        VisualitzaMaterial,
        VisualitzaActivitat,
        VisualitzaEntitat,
        VisualitzaEmpleat,
        PraePlanHeader
    }
}

</script>


<style scoped>

main {
    margin: 0px 15%;
}

</style>