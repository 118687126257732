<template>
  <PraePlanHeader :praePlanLogo="true" :title="'Home'"> </PraePlanHeader>
  <main>
    <calendar />
  </main>
  <footer>
    <button class="PraePlanButton" @click="goToActivitats">
      Go to Activitats
    </button>
    <button class="PraePlanButton" @click="goToEmpleats">Go to Empleats</button>
    <button class="PraePlanButton" @click="goToEntitats">Go to Entitats</button>
    <button class="PraePlanButton" @click="goToMaterials">
      Go to Materials
    </button>
  </footer>
</template>

<script>
import calendar from "./home_components/Calendar.vue";
import PraePlanHeader from "@/main_components/generic_components/PraePlanHeader.vue";

export default {
  name: "Home.vue",
  data() {
    return {
      user_name: "Profile",
      showProfileOptions: false,
    };
  },
  methods: {
    goToActivitats() {
      this.$router.push({ name: "Activitats" });
    },
    goToEmpleats() {
      this.$router.push({ name: "Empleats" });
    },
    goToEntitats() {
      this.$router.push({ name: "Entitats" });
    },
    goToMaterials() {
      this.$router.push({ name: "Materials" });
    },
    getUserName() {
      var temp = this.$store.state.user.user_name;
      if (temp) {
        this.user_name = temp;
      }
    },
    logOut() {
      this.$store.dispatch("setUserName", "");
      this.$store.dispatch("setAccessToken", "");
      this.$store.dispatch("setRefreshToken", "");
      this.$store.dispatch("setMaterials");
      this.$router.push({ name: "LogIn" });
    },
  },
  created() {
    this.getUserName();
    console.log(this.$store.state.user.user_type);
  },
  components: {
    calendar,
    PraePlanHeader,
  },
};
</script>

<style scoped>
main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.widget-calendar-wrapper.relative.w-full.h-screen.flex.flex-row.flex-nowrap.bg-white.min-h-screen {
  min-height: auto !important;
  height: 100% !important;
}

.calendar-wrapper.w-full.mt-4.overflow-y-auto.custom-scrll {
  height: 100% !important;
}
</style>
