<template>
  <div>
    <label>Nom.*</label>
    <input type="text" v-model="entity.name" placeholder="Nom de l'entitat" required>
  </div>
  <div>
    <label>Tipus.*</label>
    <select v-model="entity.type" required>
      <option disabled value=''>Selecciona el tipus</option>
      <option>Particular</option>
      <option>Empresa</option>
    </select>
  </div>
  <div>
    <label>Correu.</label>
    <input type="text" v-model="entity.mail" placeholder="Correu electronic de contacte">
  </div>
  <div>
    <label>Phone.</label>
    <input type="text" v-model="entity.phone" placeholder="Telèfon de contacte">
  </div>
  <div>
    <label>Descripció.</label>
    <textarea type="text" v-model="entity.description" placeholder="Descripció del tipus de material"></textarea>
  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'CreaEntitat.vue',
  data() {
    return {
      entity: {
        name: '',
        type: '',
        mail: null,
        phone: null,
        description: null,
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Entitats' })
    },
    creaElement() {
      console.log('Entity:', this.entity.name)

      const path = process.env.VUE_APP_BACKEND_URL + '/entity'
      const parameters = {
        'name': this.entity.name,
        'type': this.entity.type,
        'mail': this.entity.mail,
        'phone': this.entity.phone,
        'description': this.entity.description
      }

      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      }

      axios.post(path, parameters, config)
        .then((res) => {
          console.log('Entity created!!', res.data)
        })
        .catch((error) => {
          console.error(error)
          alert('Error: ' + error.message)
        })
        .finally(() => {
          this.$router.push({ name: 'Entitats' })
          // document.querySelector('#signInButton').disabled = false
        })
    }
  },
}

</script>