<template>
  <ItemList :listType="listType">
  </ItemList>
</template>

<script>
import ItemList from '@/main_components/generic_components/ItemList.vue';
import { ListType } from '@/main_components/generic_components/gloablTypes';

export default {
  name: 'Materials.vue',
  data() {
    return {
      listType: ListType.MATERIAL
    }
  },
  components: {
    ItemList,
  }
}
</script>