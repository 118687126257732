<template>
  <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.72 77.4">
    <g id="Layer_3" data-name="Layer 3">
      <g>
        <path
          d="m110.74,25.24c-2.34-2.07-5.5-3.11-9.48-3.11h-9.58c-.85,0-1.54.69-1.54,1.54v32.54c0,.85.69,1.54,1.54,1.54h3.11c.85,0,1.54-.69,1.54-1.54v-11.67h4.86c4.13,0,7.33-.97,9.62-2.91,2.29-1.94,3.44-4.66,3.44-8.15s-1.17-6.15-3.51-8.23Zm-4.44,12.75c-1.16,1.04-2.84,1.55-5.04,1.55h-4.93v-12.43h5.1c2.05.03,3.67.62,4.84,1.77s1.76,2.69,1.76,4.61-.58,3.45-1.74,4.49Z"/>
        <path
          d="m215.17,25.24c-2.34-2.07-5.5-3.11-9.48-3.11h-9.58c-.85,0-1.54.69-1.54,1.54v32.54c0,.85.69,1.54,1.54,1.54h3.11c.85,0,1.54-.69,1.54-1.54v-11.67h4.86c4.13,0,7.33-.97,9.62-2.91,2.29-1.94,3.44-4.66,3.44-8.15s-1.17-6.15-3.51-8.23Zm-4.44,12.75c-1.16,1.04-2.84,1.55-5.04,1.55h-4.93v-12.43h5.1c2.05.03,3.67.62,4.84,1.77,1.17,1.15,1.76,2.69,1.76,4.61s-.58,3.45-1.74,4.49Z"/>
        <path
          d="m121.93,34.24l-.07-1.5c-.04-.82-.72-1.46-1.54-1.46h-2.67c-.85,0-1.54.69-1.54,1.54v23.39c0,.85.69,1.54,1.54,1.54h2.87c.85,0,1.54-.69,1.54-1.54v-14.53c0-2.6,1.93-4.85,4.52-5.12.31-.03.64-.05.98-.05.28,0,.55,0,.82.02.87.05,1.61-.65,1.61-1.53v-2.65c.02-.8-.59-1.47-1.38-1.54-.22-.02-.46-.03-.71-.03-2.54,0-4.53,1.15-5.97,3.45Z"/>
        <path
          d="m151.97,39.44c-.05-2.82-.99-5.01-2.84-6.57-1.84-1.56-4.31-2.34-7.41-2.34-2.04,0-3.89.36-5.54,1.08-1.66.72-2.96,1.71-3.93,2.97-.5.66-.87,1.35-1.11,2.06-.33.99.42,2.02,1.46,2.02h2.86c.65,0,1.24-.4,1.45-1.02s.58-1.12,1.14-1.56c.86-.68,1.97-1.02,3.33-1.02,1.57,0,2.73.41,3.5,1.24.77.82,1.15,1.92,1.15,3.29v1.74h-3.64c-3.9,0-6.89.75-8.99,2.26-2.1,1.51-3.14,3.67-3.14,6.49,0,2.23.84,4.11,2.52,5.63,1.68,1.52,3.85,2.27,6.51,2.27s5.05-.99,6.95-2.96c.09.5.18.95.28,1.33.18.68.79,1.14,1.49,1.14h2.89c.98,0,1.71-.9,1.5-1.86-.27-1.25-.41-2.74-.41-4.25v-11.94Zm-5.94,8.88c0,1.65-.86,3.21-2.3,4.02-.01,0-.02.01-.03.02-1.04.57-2.14.86-3.31.86-1.27,0-2.29-.34-3.05-1.02-.76-.68-1.14-1.59-1.14-2.73,0-1.4.55-2.49,1.66-3.25,1.11-.77,2.76-1.15,4.97-1.15h3.2v3.25Z"/>
        <path
          d="m166.72,30.79c-2.27,0-4.32.58-6.16,1.74-1.84,1.16-3.27,2.78-4.29,4.86-1.02,2.08-1.53,4.44-1.53,7.08v.73c0,3.95,1.17,7.11,3.51,9.48,2.34,2.37,5.39,3.56,9.16,3.56,2.2,0,4.19-.44,5.96-1.31,1.3-.64,2.42-1.48,3.33-2.51.55-.62.5-1.56-.09-2.13l-1.06-1.01c-.6-.57-1.53-.56-2.13,0-1.55,1.47-3.44,2.21-5.67,2.21-1.94,0-3.55-.64-4.83-1.91-1.28-1.27-2.01-3-2.19-5.19h15.76c.85,0,1.54-.69,1.54-1.54v-.86c0-4.21-.98-7.46-2.95-9.76-1.97-2.3-4.75-3.45-8.35-3.45Zm-5.89,11.25c.28-2.04.92-3.63,1.92-4.77,1-1.14,2.32-1.71,3.95-1.71s2.94.51,3.86,1.53c.93,1.02,1.59,2.85,1.59,4.95h-11.32Z"/>
        <rect x="220.55" y="22.13" width="5.94" height="35.61" rx="1.54" ry="1.54"/>
        <path
          d="m250.99,39.44c-.05-2.82-.99-5.01-2.84-6.57-1.84-1.56-4.31-2.34-7.41-2.34-2.04,0-3.89.36-5.54,1.08-1.66.72-2.96,1.71-3.93,2.97-.5.66-.87,1.35-1.11,2.06-.33.99.42,2.02,1.46,2.02h2.86c.65,0,1.24-.4,1.45-1.02s.58-1.12,1.14-1.56c.86-.68,1.97-1.02,3.33-1.02,1.57,0,2.73.41,3.5,1.24.77.82,1.15,1.92,1.15,3.29v1.74h-3.64c-3.9,0-6.89.75-8.99,2.26-2.1,1.51-3.14,3.67-3.14,6.49,0,2.23.84,4.11,2.52,5.63,1.68,1.52,3.85,2.27,6.51,2.27s5.05-.99,6.95-2.96c.09.5.18.95.28,1.33.18.68.79,1.14,1.49,1.14h2.89c.98,0,1.71-.9,1.5-1.86-.27-1.25-.41-2.74-.41-4.25v-11.94Zm-5.94,8.88c0,1.65-.86,3.21-2.3,4.02-.01,0-.02.01-.03.02-1.04.57-2.14.86-3.31.86-1.27,0-2.29-.34-3.05-1.02-.76-.68-1.14-1.59-1.14-2.73,0-1.4.55-2.49,1.66-3.25,1.11-.77,2.76-1.15,4.97-1.15h3.2v3.25Z"/>
        <path
          d="m268.31,30.54c-3.18,0-5.75,1.18-7.7,3.55l-.09-1.61c-.05-.81-.72-1.45-1.54-1.45h-2.61c-.85,0-1.54.69-1.54,1.54v23.39c0,.85.69,1.54,1.54,1.54h2.87c.85,0,1.54-.69,1.54-1.54v-15.14c0-2.94,2.35-5.38,5.28-5.41.02,0,.05,0,.07,0,1.65,0,2.83.4,3.56,1.21.73.81,1.09,2.05,1.09,3.73v15.61c0,.85.69,1.54,1.54,1.54h2.87c.85,0,1.54-.69,1.54-1.54v-15.95c-.1-6.31-2.9-9.47-8.41-9.47Z"/>
      </g>
      <g>
        <path d="m47.63,19.49h-2.34v29.87h2.34s2.34,0,2.34-1.99v-25.88c0-1.99-2.34-1.99-2.34-1.99Z"/>
        <path d="m33.6,13.68h-2.34v29.87h2.34s2.34,0,2.34-1.99V15.67c0-1.99-2.34-1.99-2.34-1.99Z"/>
        <path
          d="m52.27,0H0v77.4h52.27c13.88,0,25.13-11.25,25.13-25.13v-27.14c0-13.88-11.25-25.13-25.13-25.13Zm7.05,34.42v7.96c0,3.71-2.98,6.83-7.02,7.71-.75.16-1.53.25-2.34.25h-4.68v16.03c0,1-1.17,1-1.17,1h-7.02c-1.17,0-1.17-1-1.17-1v-21.84h-4.68v16.03c0,1-1.17,1-1.17,1h-7.02c-1.17,0-1.17-1-1.17-1V13.68s0-1,1.17-1h12.86c4.29,0,7.89,2.46,9,5.81h5.03c5.17,0,9.35,3.57,9.35,7.96v7.96Z"/>
      </g>
    </g>
  </svg>
</template>