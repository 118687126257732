<template>
  <v-calendar ref="calendar" :attributes="attrs" @dayclick="selectDay" @did-move="changeDate" expanded>
    <template #day-popover="{ day }">
      <div class="flex justify-center" style="max-height: 300px; overflow-y: auto; width: 200px">
        <div v-for="event in getEventsByDate(day)" :key="event.id" style="display: flex; flex-direction: row">
          <h4 style="font-size: 16px">
            {{ event.time.split(":")[0] }}:{{ event.time.split(":")[1] }}
            {{ event.activity_name }}
          </h4>
          <p style="font-size: 14px">{{ event.description }}</p>
          <p style="font-size: 14px">{{ event.urgency }}</p>
          <div style="height: 20px"></div>
        </div>
      </div>
    </template>
  </v-calendar>
</template>

<script>
import axios from "axios";

export default {
  props: {
    // eventList: Array,
  },
  data() {
    return {
      changingDateData: undefined,
      isClicked: false,
      attrs: [],
      rawAttributes: [],
      viewedMonths: [],
    };
  },
  methods: {
    changeDate(data) {
      const days = data[0].viewDays;
      const date = new Date(days[Math.floor(Object.keys(days).length / 3)].id);
      this.getEventList(date);
    },
    selectDay(date) {
      const day = date.date.getDate();
      date.date.setUTCHours(12, 0, 0, 0);
      date.date.setDate(day);

      const todaySessions = this.getEventsByDate({
        day: date.date.getDate(),
        month: date.date.getMonth() + 1,
        year: date.date.getFullYear(),
      });
      const emitData = {
        date: date.date,
        sessions: todaySessions,
      };
      this.$emit("day-selected", emitData);
    },
    getEventList(dateRef) {
      const codedDate = this.codeDate(dateRef);
      if (this.viewedMonths.find((month) => month === codedDate)) {
        return;
      } else {
        this.viewedMonths.push(codedDate);
      }

      const path = process.env.VUE_APP_BACKEND_URL + "/session";
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };
      const parameters = {
        date: dateRef,
      };

      axios
        .put(path, null, { params: parameters, headers: config.headers })
        .then((response) => {
          const mappedResponse = response.data.map((event) => {
            event.time = new Date(event.date).toLocaleTimeString();
            event.date = new Date(event.date).toLocaleDateString();
            return event;
          });
          this.rawAttributes = [...this.rawAttributes, ...mappedResponse];
          this.mapAttributes(this.rawAttributes);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    codeDate(date) {
      return `${date.getFullYear()}-${date.getMonth()}`;
    },
    getEventsByDate(date) {
      const returnValue = this.rawAttributes
        .filter((event) => {
          const eventDate = event.date.split("/").map((x) => parseInt(x));
          return (
            eventDate[0] === date.month &&
            eventDate[1] === date.day &&
            eventDate[2] === date.year
          );
        })
        .sort((a, b) => {
          const timeA = a.time.split(":");
          const timeB = b.time.split(":");
          const hourA = parseInt(timeA[0]);
          const minuteA = parseInt(timeA[1]);
          const secondA = parseInt(timeA[2]);
          const hourB = parseInt(timeB[0]);
          const minuteB = parseInt(timeB[1]);
          const secondB = parseInt(timeB[2]);

          if (hourA !== hourB) {
            return hourA - hourB;
          } else if (minuteA !== minuteB) {
            return minuteA - minuteB;
          } else {
            return secondA - secondB;
          }
        });
      return returnValue;
    },
    mapAttributes(rawAttributes) {
      this.attrs = [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
        ...rawAttributes.map((event) => {
          var splitDate = event.date.split("/");
          var month = splitDate[0];
          var day = splitDate[1];
          var year = splitDate[2];
          return {
            key: `${event.id}-${event.name}`,
            dates: new Date(year, month - 1, day),
            description: event,
            popover: true,
            dot: {
              color: "green",
            },
          };
        }),
      ];
    },
  },
  mounted() {
    this.getEventList(new Date());
  },
};
</script>
