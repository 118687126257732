<template>
    <div class="dataContainer">
        <div>
            <label>
                Dni:
            </label>
            <span>
                {{ empleat.dni }}
            </span>
        </div>
        <div>
            <label>
                SocialSecurity:
            </label>
            <span>
                {{ empleat.socialSecurity }}
            </span>
        </div>
        <div>
            <label>
                Tipus de Contracte:
            </label>
            <span>
                {{ staffTypes[empleat.contractType] }}
            </span>
        </div>
        <div>
            <label>
                Preu Hora:
            </label>
            <span>
                {{ empleat.priceHour }}€
            </span>
        </div>
        <div>
            <label>
                Preu Hora Extra:
            </label>
            <span>
                {{ empleat.priceHourExtra }}€
            </span>
        </div>
        <div v-if="empleat.description !== null">
            <label>
                Description:
            </label>
            <span>
                {{ empleat.description }}
            </span>
        </div>
    </div>
    <div class="availabilityContainer">
        <h1>Disponibilitat.</h1>
    </div>
</template>


<script>
import { StaffTypes } from '@/main_components/generic_components/gloablTypes';
import axios from 'axios';

export default {
    name: 'VisualitzaEmpleat.vue',
    data() {
        return {
            id: 0,
            empleat: {},
            staffTypes: StaffTypes
        };
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'Empleats' })
        },
        goToEdit() {
            this.$router.push({ name: 'EditaEmpleat', query: { id: this.id } })
        },
        getElement() {
            // Gathering data from buffer in case there is
            let empleat = this.$store.state.buffer.viewEmpleat
            if (Object.keys(empleat).length > 0 && empleat.id == this.id) {
                this.empleat = empleat;
                this.$emit('setName', this.empleat.name)
                return;
            }
            const path = process.env.VUE_APP_BACKEND_URL + '/staff/' + this.id;
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            };
            axios.get(path, config)
                .then((res) => {
                    // We add the new material types to the list.
                    this.empleat = res.data;
                    this.$store.dispatch('setViewEmpleat', this.empleat)
                    this.$emit('setName', this.empleat.name)
                })
                .catch((error) => {
                    console.error(error);
                    alert('Empleat!\nHi ha hagut un error: ' + error.message);
                }).finally(() => {
                    // document.querySelector('#signInButton').disabled = false
                });
        }
    },
    created() {
        this.id = this.$route.query.id;
    }
}

</script>


<style scoped>
.dataContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.dataContainer>div {
    padding: 0.5rem;
    display: flex;
}

.dataContainer>div>* {
    padding: 2px;
}

.dataContainer>div>label {
    margin-right: 5px;
    white-space: nowrap;
}

.availabilityContainer {
    margin-top: 60px;
}

.availabilityContainer>h1 {
    text-align: left;
    font-size: 1.5rem;
}
</style>