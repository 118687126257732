const getDefaultState = () => {
  return {
    user_name: null,
    user_type: null,
    token: null,
  };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    UPDATE_USERNAME(state, payload) {
      state.user_name = payload;
    },
    UPDATE_TOKEN(state, payload) {
      state.token = payload;
    },
    UPDATE_USERTYPE(state, payload) {
      state.user_type = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setToken(context, payload) {
      context.commit("UPDATE_TOKEN", payload);
    },
    setUserName(context, payload) {
      context.commit("UPDATE_USERNAME", payload);
    },
    setUserType(context, payload) {
      context.commit("UPDATE_USERTYPE", payload);
    },
    resetUser(context) {
      context.commit("RESET_STATE");
    },
  },
};
