<template>
  <div class="summaryContainer">
    Total: {{ materialAmount }} | Disponible: {{ materialAmount }}
  </div>
  <div class="availabilityContainer available">
    <h1>MATERIAL DISPONIBLE</h1>
    <table>
      <tr>
        <th class="nameColumn">Nom</th>
        <th>Quantitat</th>
      </tr>
      <tr v-for="(mat, i) in material.material" v-bind:key="i">
        <td>{{ mat.name }}</td>
        <td>{{ mat.amount }}</td>
      </tr>
    </table>
  </div>
  <div class="availabilityContainer unavailable">
    <h1>MATERIAL NO DISPONIBLE</h1>
    <p>Taula amb la llista de material no disponible del tipus amb ON està i QUAN tornarà a estar dispo</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Material.vue',
  data() {
    return {
      id: 0,
      material: {},
      materialAmount: 0
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Materials' });
    },
    goToEdit() {
      this.$router.push({ name: 'EditaMaterial', query: { id: this.id } });
    },
    getElement() {
      // Gathering data from buffer in case there is
      let material = this.$store.state.buffer.viewMaterial
      if (Object.keys(material).length > 0 && material.id == this.id) {
        this.material = material;
        this.$emit('setName', this.material.name)
        for (let i = 0; i < this.material.material.length; i++) {
          this.materialAmount += this.material.material[i].amount;
        }
        return;
      }
      const path = process.env.VUE_APP_BACKEND_URL + '/materialType/' + this.id;
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      };
      axios.get(path, config)
        .then((resType) => {
          // We add the new material types to the list.
          this.material = resType.data;
          this.$emit('setName', this.material.name)
          this.$store.dispatch('setViewMaterial', this.material)
          for (let i = 0; i < this.material.material.length; i++) {
            this.materialAmount += this.material.material[i].amount;
          }
        })
        .catch((error) => {
          console.error(error);
          alert('MaterialType!\nHi ha hagut un error: ' + error.message);
        }).finally(() => {
          // document.querySelector('#signInButton').disabled = false
        });
    }
  },
  created() {
    this.id = this.$route.query.id;
  }
}
</script>

<style scoped>
.summaryContainer {
  padding: 30px 0px 10px 5%;
  font-size: 2.3rem;
  border-bottom: 2px solid gray;
  text-align: left;
}

.availabilityContainer {
  padding: 30px 10%;
  border-bottom: 1px solid gray;
}

.availabilityContainer>h1 {
  font-size: 1.8rem;
  text-align: left;
  padding-bottom: 20px;
}

table {
  background-color: #ececec;
  border-radius: 10px;
  border: 1px solid rgb(43, 43, 43);
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;
  text-align: left;
}

td,
th {
  padding: 10px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:last-child>td {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.nameColumn {
  width: 70%;
}
</style>
