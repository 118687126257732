<template>
  <div v-show="showProfileOptions" id="ProfileDiv">
    <ul>
      <li>
        <button @click='logOut()'>LogOut</button>
      </li>
    </ul>
  </div>
  <header @click.self='showProfileOptions = false'>
    <button class="PraePlanLogo" v-if="praePlanLogo == true" @click="this.$router.push({name: 'Home'})">
      <PraePlanLogo />
    </button>
    <div class="Title">
        {{ title }}
    </div>
    <div class="Profile">
      <span>
        {{ user_name }}
      </span>
      <img src="@/assets/icons/defaultProfile.png" id="HeaderProfileIcon">
    </div>
  </header>
</template>

<script>

import PraePlanLogo from '@/assets/icons/PraePlanLogo.vue';

export default {
  name: 'PraePlanHeader',
  props: ['praePlanLogo', 'title'],
  data() {
    return {
      user_name: 'Profile',
      showProfileOptions: false
    }
  },
  methods: {
    getUserName() {
      var temp = this.$store.state.user.user_name
      if (temp) {
        this.user_name = temp;
      }
    },
    logOut() {
      console.log('Before:');
      console.log(this.$store.state.user);
      console.log(this.$store.state.buffer);
      this.$store.dispatch('resetAll')
      console.log('After:');
      console.log(this.$store.state.user);
      console.log(this.$store.state.buffer);
      this.$router.push({ name: 'LogIn' })
    }
  },
  created() {
    this.getUserName()
  },
  mounted() {
    document.addEventListener('click', (evt) => {
      if(this.showProfileOptions == true) {
        this.showProfileOptions = false;
        return;
      }
      const flyoutEl = document.getElementById("HeaderProfileIcon");
      let targetEl = evt.target;
      do {
        if(flyoutEl == targetEl){
            this.showProfileOptions = true;
            return
        }
        targetEl = targetEl.parentNode;
      } while(targetEl);
    })
  },
  components: {
    PraePlanLogo
  }
}

/*
document.addEventListener("click", (evt) => {
  const flyoutEl = document.getElementById("flyout-example");
  let targetEl = evt.target; // clicked element      
  do {
    if(targetEl == flyoutEl) {
      // This is a click inside, does nothing, just return.
      document.getElementById("flyout-debug").textContent = "Clicked inside!";
      return;
    }
    // Go up the DOM
    targetEl = targetEl.parentNode;
  } while (targetEl);
  // This is a click outside.      
    document.getElementById("flyout-debug").textContent = "Clicked outside!";
});
*/


</script>

<style scoped>

.Title {
  font-size: 2.5rem;
  position: relative;
  color: #fffffe;
}

#ProfileDiv {
  position: absolute;
  top: 67px;
  right: 0px;
  z-index: 20;
  height: auto;
  background-color: gray;
  color: #fffffe;
  width: 200px;
}

#ProfileDiv > * {
  padding: 5px;
}

.PraePlanLogo > * {
  height: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  fill: #fffffe
}

.Profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fffffe;
  cursor: default;
}

.Profile > img {
  height: 50px;
  padding: 5px 20px 5px 15px;
  cursor: pointer;
}

header {
  z-index: 1;
  top: 0px;
  right: 0px;
  left: 0px;
  height: auto;
  background-color: #da5e9d;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>