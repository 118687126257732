<template>
  <PraePlanHeader :prae-plan-logo="true" :title="'Modificant ' + listTypes[type]">
  </PraePlanHeader>

  <form @submit.prevent="editaElement()" id="my-form">
    <div id="gridContainer">
      <component :is="'Edita' + listTypes[type]" ref="editaElement">
      </component>
    </div>
  </form>
  <footer>
    <button class="PraePlanButton" @click="$refs.editaElement.goBack()">&#60;</button>
    <button class="PraePlanButton" @click="$refs.editaElement.eliminaElement()"
      v-if="$store.state.user.user_type !== 'STAFF'">Eliminar</button>
    <button type="submit" form="my-form" class="PraePlanButton">Confirmar</button>
  </footer>
</template>

<script>
import PraePlanHeader from '@/main_components/generic_components/PraePlanHeader.vue'
import EditaMaterial from '@/main_components/materials_components/EditaMaterial.vue'
import EditaActivitat from '@/main_components/activitats_components/EditaActivitat.vue'
import EditaEntitat from '@/main_components/entitats_components/EditaEntitat.vue'
import EditaEmpleat from '@/main_components/empleats_components/EditaEmpleat.vue'
import { ListType } from '@/main_components/generic_components/gloablTypes';


export default {
  data() {
    return {
      type: null,
      listTypes: ListType
    }
  },
  methods: {
    editaElement() {
      console.log('Editant Element');
      this.$refs.editaElement.editaElement()
    }
  },
  created() {
    this.type = this.$route.name.substr(5).toUpperCase()
  },
  mounted() {
    this.$refs.editaElement.getElement()
  },
  components: {
    EditaMaterial,
    EditaActivitat,
    EditaEntitat,
    EditaEmpleat,
    PraePlanHeader
  }
}

</script>


<style>

#gridContainer {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

#gridContainer>div {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

#gridContainer>div>* {
  padding: 2px;
}

#gridContainer>div>label {
  margin-right: 5px;
  white-space: nowrap;
  text-align: left;
}

#gridContainer>div>input,
#gridContainer>div>textarea {
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
}
</style>