<template>
    <div>
        <label>Nom.*</label>
        <input type="text" v-model="entitat.name" placeholder="Nom de l'entitat" required>
    </div>
    <div>
        <label>
            Tipus.*
        </label>
        <select v-model="entitat.type" required>
            <option disabled value=''>Selecciona el tipus</option>
            <option value="PARTICULAR">Particular</option>
            <option value="EMPRESA">Empresa</option>
        </select>
    </div>
    <div>
        <label>
            Mail.
        </label>
        <input v-model="entitat.mail">
    </div>
    <div>
        <label>
            Phone.
        </label>
        <input v-model="entitat.phone">
    </div>
    <div>
        <label>
            Description.
        </label>
        <textarea v-model="entitat.description">
                </textarea>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: 'VisualitzaEntitat.vue',
    data() {
        return {
            id: 0,
            entitat: {}
        };
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'VisualitzaEntitat', query: { id: this.id } })
        },
        getElement() {
            // Gathering data from buffer in case there is
            let entitat = structuredClone(this.$store.state.buffer.viewEntitat)
            if (Object.keys(entitat).length > 0 && entitat.id == this.id) {
                this.entitat = entitat;
                return;
            }
            const path = process.env.VUE_APP_BACKEND_URL + '/entity/' + this.id;
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            };
            axios.get(path, config)
                .then((res) => {
                    // We add the new material types to the list.
                    this.entitat = res.data;
                    this.$store.dispatch('setViewEntitat', this.entitat)
                })
                .catch((error) => {
                    console.error(error);
                    alert('Entitat!\nHi ha hagut un error: ' + error.message);
                }).finally(() => {
                    // document.querySelector('#signInButton').disabled = false
                });
        },
        editaElement() {
            console.log('Entity:', this.entitat.name)

            const path = process.env.VUE_APP_BACKEND_URL + '/entity/' + this.id
            const parameters = this.entitat
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            }

            axios.put(path, parameters, config)
                .then((res) => {
                    console.log('Entity updated!!', res.data)
                    this.$store.dispatch('setViewEntitat', this.entitat)
                })
                .catch((error) => {
                    console.error(error)
                    alert('Error: ' + error.message)
                })
                .finally(() => {
                    this.$router.push({ name: 'Entitats' })
                    // document.querySelector('#signInButton').disabled = false
                })
        },
        eliminaElement() {
            const path = process.env.VUE_APP_BACKEND_URL + '/entity/' + this.id;
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            };
            axios.delete(path, config)
                .then((res) => {
                    // We add the new material types to the list.
                    console.log(res.data)
                })
                .catch((error) => {
                    console.error(error);
                    alert('Entitat!\nHi ha hagut un error: ' + error.message);
                }).finally(() => {
                    this.$router.push({ name: 'Entitats' })
                });
        }
    },
    created() {
        this.id = this.$route.query.id;
    }
}

</script>