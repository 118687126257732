<template>
  <div>
    <label>Nom.*</label>
    <input v-model="empleat.name" placeholder="Nom de l'empleat" required>
  </div>
  <div>
    <label>Dni.*</label>
    <input v-model="empleat.dni" placeholder="DNI de l'empleat" required>
  </div>
  <div>
    <label>SS.*</label>
    <input v-model="empleat.socialSecurity" placeholder="Numero de la seguretat social" required>
  </div>
  <div>
    <label>Tipus de contracte.*</label>
    <select v-model="empleat.contractType" required>
      <option disabled value=''>Selecciona el tipus</option>
      <option v-for="(staffType, i) in staffTypes" v-bind:key="i" :value='i'>
        {{ staffType }}
      </option>
    </select>
  </div>
  <div>
    <label>Preu Hora.*</label>
    <input v-model="empleat.priceHour" placeholder="Preu Hora de l'empleat" required>
  </div>
  <div>
    <label>Preu Hora Extra.*</label>
    <input v-model="empleat.priceHourExtra" placeholder="Preu Hora Extra de l'empleat" required>
  </div>
  <div class="description">
    <label>Descripció.</label>
    <textarea v-model="empleat.description" placeholder="Descripció de l'empleat" rows="4" cols="50"></textarea>
  </div>
</template>

<script>
import { StaffTypes } from '@/main_components/generic_components/gloablTypes';
import axios from 'axios';

export default {
  name: 'CreaEmpleat.vue',
  data() {
    return {
      id: null,
      empleat: {},
      staffTypes: StaffTypes
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'VisualitzaEmpleat', query: { id: this.id } });
    },
    editaElement() {
      console.log('Staff:', this.empleat.name)

      const path = process.env.VUE_APP_BACKEND_URL + '/staff/' + this.id
      const parameters = this.empleat
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      }

      axios.put(path, parameters, config)
        .then((res) => {
          console.log('Staff updated!!', res.data)
          this.$store.dispatch('setViewEmpleat', this.empleat)
        })
        .catch((error) => {
          console.error(error)
          alert('Error: ' + error.message)
        })
        .finally(() => {
          this.$router.push({ name: 'Empleats' })
          // document.querySelector('#signInButton').disabled = false
        })
    },
    getElement() {
      // Gathering data from buffer in case there is
      let empleat = this.$store.state.buffer.viewEmpleat
      if (Object.keys(empleat).length > 0 && empleat.id == this.id) {
        this.empleat = empleat;
        return;
      }
      console.log('Not Stored!');
      const path = process.env.VUE_APP_BACKEND_URL + '/staff/' + this.id;
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      };
      axios.get(path, config)
        .then((res) => {
          // We add the new material types to the list.
          this.empleat = res.data;
          this.$store.dispatch('setViewEmpleat', this.empleat)
        })
        .catch((error) => {
          console.error(error);
          alert('Entitat!\nHi ha hagut un error: ' + error.message);
        }).finally(() => {
          // document.querySelector('#signInButton').disabled = false
        });
    },
    eliminaElement() {
      console.log('Eliminant Staff:', this.empleat.name)

      const path = process.env.VUE_APP_BACKEND_URL + '/staff/' + this.id
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      }

      axios.delete(path, config)
        .then((res) => {
          console.log('Staff deleted!!', res.data)
        })
        .catch((error) => {
          console.error(error)
          alert('Error: ' + error.message)
        })
        .finally(() => {
          this.$router.push({ name: 'Empleats' })
          // document.querySelector('#signInButton').disabled = false
        })
    }
  },
  created() {
    this.id = this.$route.query.id
  }
}
</script>


<style scoped>
form {
  margin: 0px 15%;
}

#gridContainer {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

#gridContainer>div {
  padding: 0.5rem;
  display: flex;
}

#gridContainer>div>* {
  padding: 2px;
}

#gridContainer>div>label {
  margin-right: 5px;
  white-space: nowrap;
}

#gridContainer>div>input {
  width: 100%;
}

.description {
  margin-top: 20px;
}

.description>* {
  padding-right: 20px;
  display: flex;
}

.description>textarea {
  min-height: 1rem;
  resize: auto;
}
</style>
