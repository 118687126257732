<template>
  <main id="background">
    <form id="main-container" @submit.prevent="checkLogIn">
      <div class="form-header">Log In</div>
      <div class="form-container">
        <label>Usuari</label>
        <input type="username" id="inputUser" placeholder="Usuari" required autofocus v-model="username" />
      </div>
      <div class="form-container">
        <label>Contrasenya</label>
        <input type="password" id="passwordInput" placeholder="Contrasenya" required v-model="password" />
        <div clasS="togglePasswordContainer" @click="togglePassword">
          <input type="checkbox" v-model="showPassword" />
          <span>Mostrar contrasenya</span>
        </div>
      </div>
      <button type="submit" id="logInButton" :disabled="buttonDisabled">
        Entrar
      </button>
    </form>
  </main>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LogIn.vue',
  data() {
    return {
      username: null,
      password: null,
      showPassword: false,
      buttonDisabled: false,
    };
  },
  methods: {
    async checkLogIn() {
      console.log('🚀 ~ checkLogIn ~ checkLogIn:');
      /* Desactivando el botón de register no poder hacer muchas request y feedback a usuario */
      this.buttonDisabled = true;

      const parameters =
        'username=' + this.username + '&password=' + this.password;
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const path = process.env.VUE_APP_BACKEND_URL + '/login';

      let loggedIn = false;
      let i = 0;
      while (!loggedIn && i < 3) {
        console.log('🚀 ~ checkLogIn ~ i:', i);
        try {
          const response = await axios.post(path, parameters, config);
          console.log('🚀 ~ checkLogIn ~ response:', response);
          this.$store.dispatch('setUserName', response.data.user_name);
          this.$store.dispatch('setUserType', response.data.type.toUpperCase());
          this.$store.dispatch('setToken', response.data.token);
          this.$router.push({ name: 'Home' });
          loggedIn = true;
        } catch (error) {
          console.log(error);
          if (
            'response' in error &&
            error.response.status >= 500 &&
            error.response.status < 600
          ) {
            alert(error.response.data);
            this.buttonDisabled = false;
            loggedIn = true;
          } else {
            console.error('Error 500, trying again');
          }
        }
        i++;
      }

      if (i === 3) {
        alert('Ha habido un problema con el servidor, inténtalo de nuevo');
      }
      this.buttonDisabled = false;
    },
    togglePassword() {
      var input = document.getElementById('passwordInput');
      if (this.showPassword) {
        input.type = 'password';
        this.showPassword = false;
      } else {
        input.type = 'text';
        this.showPassword = true;
      }
    },
  },
  created() {
    if (this.$store.state.user.token !== '') {
      this.$router.push({ name: 'Home' });
    }
  },
};
</script>

<style scoped>
#background {
  flex-flow: row;
  font-family: Arial, Helvetica, sans-serif;

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  flex: 1 1 auto;
  display: flex;
  justify-content: center;

  background: linear-gradient(to right, #ff71a4, #ff005b);
}

#main-container {
  margin: auto;
  background-color: rgba(252, 235, 245, 0.7);
  box-shadow: rgb(166 72 109 / 69%) 0px 10px 20px 7px;
  padding: 30px 30px 50px 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.form-header {
  margin-bottom: 20px;
  font-size: 1.75rem;
}

.form-container {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-container>label {
  margin-right: auto;
  font-size: 1.25rem;
}

.form-container>input {
  border: 1px solid #383838;
  background-color: white;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}

#logInButton {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 2px solid #383838;
  border-radius: 90px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgb(224 74 162 / 43%) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: 'JetBrains Mono', monospace;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding: 8px;
  margin-top: 30px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

#logInButton:focus {
  box-shadow: rgb(224 74 162 / 43%) 0 0 0 1.5px inset,
    rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    rgb(224 74 162 / 43%) 0 -3px 0 inset;
}

#logInButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgb(224 74 162 / 43%) 0 -3px 0 inset;
  transform: translateY(-2px);
}

#logInButton:active {
  box-shadow: rgb(224 74 162 / 43%) 0 3px 7px inset;
  transform: translateY(2px);
}

#logInButton:disabled {
  box-shadow: none;
  transform: none;
  cursor: default;
}

.togglePasswordContainer {
  font-size: 1rem;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  user-select: none;
}

.togglePasswordContainer>* {
  margin: 2px;
}
</style>
