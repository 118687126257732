<template>
    <div>
        <label for="materialType">Tipus.</label>
        <input type="text" id="materialType" v-model="materialType.name" placeholder="Nom del tipus de material"
            required>
    </div>
    <div>
        <label for="materialDescription">Descripció.</label>
        <textarea type="text" id="materialDescription" v-model="materialType.description"
            placeholder="Descripció del tipus de material"></textarea>
    </div>
    <div class="materialContainer">
        <h1>
            Materials.
        </h1>
        <div id="materialContentContainer">
            <div class="materialList" v-for="(material, i) in materialType.material" v-bind:key="i">
                <input type="text" v-model="material.name" placeholder="Nom material" required>
                <input type="number" min="0" v-model="material.amount" placeholder="Quantitat" required>
                <button type="button" @click="removeMaterial(i)">Remove</button>
            </div>
            <div class="addMaterialButton">
                <button type="button" @click="addMaterial">+</button>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    data() {
        return {
            materialType: {
                name: null,
                description: null,
                material: [
                    {
                        id_type: 0,
                        name: null,
                        amount: null,
                        description: null
                    }
                ]
            },
        }
    },
    name: 'CreaMaterial.vue',
    methods: {
        goBack() {
            this.$router.push({ name: 'Materials' })
        },
        addMaterial() {
            this.materialType.material.push({ id_type: 0, name: null, amount: null, description: null })
        },
        removeMaterial(i) {
            this.materialType.material.splice(i, i)
        },
        creaElement() {
            console.log('MaterialType:', this.materialType.name, this.materialType.description)

            const path = process.env.VUE_APP_BACKEND_URL + '/materialType'

            const parameters = {
                'name': this.materialType.name,
                'description': this.materialType.description,
                'material': this.materialType.material
            }

            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            }
            console.log(parameters)
            axios.post(path, parameters, config)
                .then((res) => {
                    console.log('MaterialType created!!', res.data)
                    this.$router.push({ name: 'Materials' })
                })
                .catch((error) => {
                    console.error(error)
                    alert('MaterialType. Error: ' + error.message)
                })
                .finally(() => {
                    // document.querySelector('#signInButton').disabled = false
                })
        }
    }
}
</script>

<style scoped>
.materialContainer {
    margin-top: 20px;
    flex-direction: column;
    grid-column: 1/-1;
}

.materialContainer>h1 {
    font-size: 2rem;
}

.materialList>* {
    padding: 6px;
    margin-left: 3px;
    margin-bottom: 7px;
    border: 1px solid black;
    border-radius: 10px;
}

.materialList>button {
    margin-left: 20px;
}

.materialList>button:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px;
    transform: translateY(-2px);
}

.materialList>button:active {
    box-shadow: inset rgba(45, 35, 66, 0.4) 0 1px 3px;
    transform: translateY(1px);
}

.addMaterialButton {
    border: 1px solid black;
    border-radius: 100px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px;
}

.addMaterialButton:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px;
    transform: translateY(-2px);
}

.addMaterialButton:active {
    box-shadow: inset rgba(45, 35, 66, 0.4) 0 1px 3px;
    transform: translateY(1px);
}
</style>