<template>
  <div style="border: 1px solid #333; border-radius: 10px">
    <h2>{{ selectedDate.getDate() }} {{ MONTHS[selectedDate.getMonth()] }}</h2>
    <div>
      <div v-for="(session, i) in sessionList" :key="i">
        <p>
          {{ session.time.split(":")[0] }}:{{ session.time.split(":")[1] }} -
          {{ Number(session.time.split(":")[0]) + session.duration }}:{{
            session.time.split(":")[1]
          }}
        </p>
        <p>{{ session.activity_name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  data() {
    return {
      MONTHS: MONTHS,
      eventName: undefined,
      description: undefined,
      urgency: "LOW",
    };
  },
  props: {
    sessionList: Array,
    selectedDate: Date,
  },
  methods: {
    submitForm() {
      const eventData = {
        name: this.eventName === "" ? undefined : this.eventName,
        description: this.description,
        urgency: this.urgency,
        date: this.selectedDate.toISOString(),
      };
      const path = process.env.VUE_APP_BACKEND_URL + "/event";
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };

      console.log(
        "Creating event with Data",
        eventData.date,
        this.selectedDate
      );
      axios
        .post(path, eventData, config)
        .then((response) => {
          console.log("Event created", response.data);
          this.$emit("event-created", response.data);
          this.eventName = undefined;
          this.description = undefined;
          this.urgency = "LOW";
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  watch: {
    sessionList: function (newVal) {
      console.log("SessionList changed", JSON.stringify(newVal));
    },
  },
};
</script>
