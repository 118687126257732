<template>
  <ItemList :listType="listType">
  </ItemList>
</template>

<script>
import ItemList from '@/main_components/generic_components/ItemList.vue'
import { ListType } from '@/main_components/generic_components/gloablTypes'

export default {
  name: 'Activitat.vue',
  data() {
    return {
      listType: ListType.ACTIVITAT
    }
  },
  created() {
  },
  components: {
    ItemList,
  }
}
</script>