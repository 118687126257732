import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
//import axios from 'axios'

import Home from "@/main_components/Home";
import LogIn from "@/main_components/LogIn";

import Activitats from "@/main_components/Activitats";

import Empleats from "@/main_components/Empleats";

import Entitats from "@/main_components/Entitats";

import Materials from "@/main_components/Materials";

import CreaTemplate from "@/main_components/generic_components/CreaTemplate";
import EditaTemplate from "@/main_components/generic_components/EditaTemplate";
import VisualitzaTemplate from "@/main_components/generic_components/VisualitzaTemplate.vue";

const routes = [
  {
    path: "/",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },

  // Acivity Routes
  {
    path: "/activities",
    name: "Activitats",
    component: Activitats,
  },
  {
    path: "/newActivity",
    name: "CreaActivitat",
    component: CreaTemplate,
  },
  {
    path: "/viewActivity",
    name: "VisualitzaActivitat",
    component: VisualitzaTemplate,
  },
  {
    path: "/editActivity",
    name: "EditaActivitat",
    component: EditaTemplate,
  },

  // Staff Routes
  {
    path: "/employee",
    name: "Empleats",
    component: Empleats,
  },
  {
    path: "/newEmployee",
    name: "CreaEmpleat",
    component: CreaTemplate,
  },
  {
    path: "/viewEmployee",
    name: "VisualitzaEmpleat",
    component: VisualitzaTemplate,
  },
  {
    path: "/editEmployee",
    name: "EditaEmpleat",
    component: EditaTemplate,
  },

  // Entity Routes
  {
    path: "/entity",
    name: "Entitats",
    component: Entitats,
  },
  {
    path: "/newEntity",
    name: "CreaEntitat",
    component: CreaTemplate,
  },
  {
    path: "/viewEntity",
    name: "VisualitzaEntitat",
    component: VisualitzaTemplate,
  },
  {
    path: "/editEntity",
    name: "EditaEntitat",
    component: EditaTemplate,
  },

  // Material Routes
  {
    path: "/materials",
    name: "Materials",
    component: Materials,
  },
  {
    path: "/newMaterialType",
    name: "CreaMaterial",
    component: CreaTemplate,
  },
  {
    path: "/viewMaterial",
    name: "VisualitzaMaterial",
    component: VisualitzaTemplate,
  },
  {
    path: "/editMaterial",
    name: "EditaMaterial",
    component: EditaTemplate,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// eslint-disable-next-line
router.beforeEach(async (to, from) => {
  if (store.state.user.token) {
    const jwtPayload = parseJwt(store.state.user.token);
    if (jwtPayload.exp < Date.now() / 1000) {
      // token expired
      store.dispatch("resetAll");
      return { name: "LogIn" };
    }
    return true;
  } else if (to.name !== "LogIn") {
    return { name: "LogIn" };
  }

  /*   let token = store.state.user.access_token
    console.log(token);
    console.log(parseJwt(token));
    let badToken = true
    if (store.state.user.access_token !== '') {
      const path = process.env.VUE_APP_BACKEND_URL + '/materialType';
      const config = {
        headers: { Authorization: `Bearer {${store.state.user.access_token}}` }
      };
      console.log(path, config);
      axios.post(path, config)
        .then((res) => {
          console.log("Router checkToken result", res.data);
          badToken = false
        }).catch((error) => {
          console.log("Router checkToken error", error);
          badToken = true
        })
    }
    if (
      // make sure the user is not authenticated
      badToken &&
      // ❗️ Avoid an infinite redirect
      to.name !== 'LogIn'
    ) {
      // redirect the user to the login page
      return { name: 'LogIn' }
    }*/
});

export default router;
