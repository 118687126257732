<template>
    <div>
        <label for="nomAct">Nom de l'activitat.* </label>
        <input type="text" id="nomAct" name="nomAct" placeholder="ex. vela" v-model="activity.name" required>
    </div>
    <div>
        <label for="numPax">Número d'asistents.* </label>
        <input type="number" id="numPax" name="numPax" min="1" max="100" v-model="activity.amount_people" required>
    </div>
    <div>
        <label for="dataIni">Data d'inici.* </label>
        <input type="datetime-local" id="dataIni" name="dataIni" min="2022-01-01" max="2299-12-31"
            v-model="activity.start_date" required>
    </div>
    <div>
        <label for="dataFin">Data final. </label>
        <input type="datetime-local" id="dataFin" name="dataFin" min="2022-01-01" max="2299-12-31"
            v-model="activity.end_date">
    </div>
    <div>
        <label for="preu">Preu.* </label>
        <input type="number" id="preu" name="preu" placeholder="Preu total de l'activitat" v-model="activity.price"
            required>
    </div>
    <div>
        <label for="paymentStatus">Estat de pagament.* </label>
        <input type="text" id="paymentStatus" name="paymentStatus" placeholder="Estat del pagament"
            v-model="activity.paymentStatus" required>
    </div>
    <div>
        <label for="activityDescription">Descripció. </label>
        <textarea type="text" id="activityDescription" name="activityDescription"
            placeholder="Descripció de l'activitat" v-model="activity.description"></textarea>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: 'VisualitzaEntitat.vue',
    data() {
        return {
            id: 0,
            activity: {}
        };
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'VisualitzaActivitat', query: { id: this.id } })
        },
        getElement() {
            // Gathering data from buffer in case there is
            let activity = structuredClone(this.$store.state.buffer.viewActivitat)
            console.log(activity);
            if (Object.keys(activity).length > 0 && activity.id == this.id) {
                this.activity = activity;
                return;
            }
            const path = process.env.VUE_APP_BACKEND_URL + '/activity/' + this.id;
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            };
            axios.get(path, config)
                .then((res) => {
                    // We add the new material types to the list.
                    this.activity = res.data;
                    this.$store.dispatch('setViewActivitat', this.activity)
                })
                .catch((error) => {
                    console.error(error);
                    alert('Activitat!\nHi ha hagut un error: ' + error.message);
                }).finally(() => {
                    // document.querySelector('#signInButton').disabled = false
                });
        },
        eliminaElement() {
            const path = process.env.VUE_APP_BACKEND_URL + '/activity/' + this.id;
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            };
            axios.delete(path, config)
                .then((res) => {
                    // We add the new material types to the list.
                    console.log(res.data)
                })
                .catch((error) => {
                    console.error(error);
                    alert('Entitat!\nHi ha hagut un error: ' + error.message);
                }).finally(() => {
                    this.$router.push({ name: 'Activitats' })
                });
        },
        editaElement() {
            console.log('Activitat:', this.activity.name)

            const path = process.env.VUE_APP_BACKEND_URL + '/activity/' + this.id
            const parameters = this.activity
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            }

            axios.put(path, parameters, config)
                .then((res) => {
                    console.log('Activity updated!!', res.data)
                    this.$store.dispatch('setViewActivitat', this.activity)
                })
                .catch((error) => {
                    console.error(error)
                    alert('Error: ' + error.message)
                })
                .finally(() => {
                    this.$router.push({ name: 'Activitats' })
                    // document.querySelector('#signInButton').disabled = false
                })
        },
    },
    created() {
        this.id = this.$route.query.id;
    }
}

</script>


<style scoped>
main {
    margin: 0px 10%;
}

.dataContainer {
    display: grid;
    grid-gap: 1rem;
    margin-top: 2.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.7rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.input {
    display: flex;
    flex-direction: row;
}

.input>* {
    padding: 0px 7px;
    border: 1px solid black;
    border-radius: 10px;
}

.input>label {
    width: auto;
    border: 0px;
}

.input>textarea {
    width: 100%;
}

.historialContainer {
    margin-top: 60px;
}

.historialContainer>h1 {
    text-align: left;
    font-size: 1.5rem;
}

.historialContainer>table {
    width: 100%;
    background-color: lightgray;
}

th {
    background-color: aliceblue;
}
</style>