<template>
  <div class="dataContainer">
    <div>
      <label> Tipus: </label>
      <span>
        {{ entitat.type }}
      </span>
    </div>
    <div>
      <label> Mail: </label>
      <span>
        {{ entitat.mail }}
      </span>
    </div>
    <div>
      <label> Phone: </label>
      <span>
        {{ entitat.phone }}
      </span>
    </div>
    <div v-if="entitat.description !== ''">
      <label> Description: </label>
      <span>
        {{ entitat.description }}
      </span>
    </div>
  </div>
  <div class="historialContainer">
    <h1>Historial de Activitats.</h1>
    <table>
      <tr>
        <th>Nom</th>
        <th>Data</th>
        <th>Descripció</th>
      </tr>
      <tr>
        <td>Nom Act1</td>
        <td>20/20/20</td>
        <td>Activitat amb cicle superior</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VisualitzaEntitat.vue',
  data() {
    return {
      id: 0,
      entitat: {},
      activities: [],
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Entitats' });
    },
    goToEdit() {
      this.$router.push({ name: 'EditaEntitat', query: { id: this.id } });
    },
    getElement() {
      // Gathering data from buffer in case there is
      let entitat = this.$store.state.buffer.viewEntitat;
      const activities = this.$store.state.buffer.viewActivitiesEntity;
      //   EntitySession;
      console.log('🚀 ~ getElement ~ entitat:', entitat);
      if (Object.keys(entitat).length > 0 && entitat.id == this.id) {
        this.entitat = entitat;
        this.$emit('setName', this.entitat.name);
        this.activities = activities;
        console.log('🚀 ~ getElement ~ activities:', activities);
        // this.$emit('setActivities', this.activities);
        return;
      }
      const path1 = process.env.VUE_APP_BACKEND_URL + '/entity/' + this.id;
      const path2 = process.env.VUE_APP_BACKEND_URL + '/activity';
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };
      axios
        .get(path1, config)
        .then((res) => {
          // We add the new material types to the list.
          this.entitat = res.data;
          this.$store.dispatch('setViewEntitat', this.entitat);
          this.$emit('setName', this.entitat.name);
        })
        .catch((error) => {
          console.error(error);
          alert('Entitat!\nHi ha hagut un error: ' + error.message);
        })
        .finally(() => {
          // document.querySelector('#signInButton').disabled = false
        });
      axios
        .get(path2, config)
        .then((res) => {
          this.activities = res.data;
          this.$store.dispatch('setActivitatsEntity', this.activities);
          console.log('hola', res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
};
</script>

<style scoped>
.dataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
  font-size: 1.7rem;
}

.dataContainer>div>* {
  padding: 0px;
}

.historialContainer {
  margin-top: 60px;
}

.historialContainer>h1 {
  text-align: left;
  font-size: 1.5rem;
}

.historialContainer>table {
  width: 100%;
  background-color: lightgray;
}

th {
  background-color: aliceblue;
}
</style>
