const getDefaultState = () => {
  return {
    materialList: [],
    viewMaterial: {},

    empleatList: [],
    viewEmpleat: {},

    entitatList: [],
    viewEntitat: {},
    viewActivitiesEntity: [],

    activitatList: [],
    viewActivitat: {},
  };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    UPDATE_MATERIALLIST(state, payload) {
      state.materialList = payload;
    },
    UPDATE_VIEWMATERIAL(state, payload) {
      state.viewMaterial = payload;
    },

    UPDATE_EMPLEATLIST(state, payload) {
      state.empleatList = payload;
    },
    UPDATE_VIEWEMPLEAT(state, payload) {
      state.viewEmpleat = payload;
    },

    UPDATE_ENTITATLIST(state, payload) {
      state.entitatList = payload;
    },
    UPDATE_VIEWENTITAT(state, payload) {
      state.viewEntitat = payload;
    },

    UPDATE_ACTIVITATLIST(state, payload) {
      state.activitatList = payload;
    },
    UPDATE_VIEWACTIVITAT(state, payload) {
      state.viewActivitat = payload;
    },
    UPDATE_ACTIVITATSENTITY(state, payload) {
      state.viewActivitiesEntity = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    // MATERIAL LIST ACTIONS
    addMaterial(context, payload) {
      const tempList = context.state.materialList;
      tempList.push(payload);
      context.commit('UPDATE_MATERIALLIST', tempList);
    },
    setMaterials(context, payload) {
      context.commit('UPDATE_MATERIALLIST', payload);
    },

    // VIEW MATERIAL ACTIONS
    setViewMaterial(context, payload) {
      context.commit('UPDATE_VIEWMATERIAL', payload);
    },

    // EMPLEAT LIST ACTIONS
    addEmpleat(context, payload) {
      const tempList = context.state.empleatList;
      tempList.push(payload);
      context.commit('UPDATE_EMPLEATLIST', tempList);
    },
    setEmpleats(context, payload) {
      context.commit('UPDATE_EMPLEATLIST', payload);
    },

    // VIEW EMPLEAT ACTIONS
    setViewEmpleat(context, payload) {
      context.commit('UPDATE_VIEWEMPLEAT', payload);
    },

    // ENTITAT LIST ACTIONS
    addEntitat(context, payload) {
      const tempList = context.state.entitatList;
      tempList.push(payload);
      context.commit('UPDATE_ENTITATLIST', tempList);
    },
    setEntitats(context, payload) {
      context.commit('UPDATE_ENTITATLIST', payload);
    },

    // VIEW ENTITAT ACTIONS
    setViewEntitat(context, payload) {
      context.commit('UPDATE_VIEWENTITAT', payload);
    },

    // ACTIVITAT LIST ACTIONS
    addActivitat(context, payload) {
      const tempList = context.state.activitatList;
      tempList.push(payload);
      context.commit('UPDATE_ACTIVITATLIST', tempList);
    },
    setActivitats(context, payload) {
      context.commit('UPDATE_ACTIVITATLIST', payload);
    },

    // VIEW ACTIVITAT ACTIONS
    setViewActivitat(context, payload) {
      context.commit('UPDATE_VIEWACTIVITAT', payload);
    },

    // ACTIVITAT ENTITY ACTIONS
    setActivitatsEntity(context, payload) {
      context.commit('UPDATE_ACTIVITATSENTITY', payload);
    },

    // GLOBAL ACTIONS
    resetBuffer(context) {
      context.commit('RESET_STATE');
    },
  },
};
