<template>
  <div class="elementHeader">
    <h2>{{ data.name }}</h2>
  </div>
  <table class="elementBody">
    <tr>
      <td>Dni:</td>
      <td>{{ data.dni }}</td>
    </tr>
    <tr>
      <td>SocialSecurity:</td>
      <td>{{ data.socialSecurity }}</td>
    </tr>
    <tr>
      <td>Tipus de Contracte:</td>
      <td>{{ staffTypes[data.contractType] }}</td>
    </tr>
    <tr>
      <td>Preu Hora:</td>
      <td>{{ data.priceHour }}€</td>
    </tr>
    <tr>
      <td>Preu Hora Extra:</td>
      <td>{{ data.priceHourExtra }}€</td>
    </tr>
  </table>
  <div class="elementFooter" v-if="data.description !== null">
    Descripció: {{ data.description }}
  </div>
</template>

<script>
import { StaffTypes } from '@/main_components/generic_components/gloablTypes';
export default {
  name: 'EmpleatElement',
  props: ['data'],
  data() {
    return {
      staffTypes: StaffTypes
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
  border-bottom: 4px solid #ccc;
  color: #333f;
  margin: 0 0 1rem 0;
}

.elementBody {
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}

tr > * {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.elementFooter {
  margin-top: 20px;
}
</style>
