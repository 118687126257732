<template>
  <div class="calendar-dashboard">
    <div class="event-list">
      <!-- Componente para listar eventos -->
      <event-list></event-list>
    </div>
    <div class="calendar-view">
      <!-- Componente del calendario -->
      <calendar-component @day-selected="updateSelectedDate" @month-changed="changeMonth" :eventList="calendarAttrs"
        :calendarDate="calendarDate" :key="calendarAttrs.length"></calendar-component>
    </div>
    <div class="event-details">
      <!-- Componente para visualizar y crear eventos -->
      <event-details :sessionList="sessionList" :selectedDate="selectedDate"></event-details>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CalendarComponent from "./CalendarComponent.vue";
import EventDetails from "./EventDetails.vue";
import EventList from "./EventList.vue";

export default {
  data() {
    return {
      selectedDate: new Date(),
      calendarDate: undefined,
      calendarAttrs: [],
      viewedMonths: [],
      sessionList: [],
    };
  },
  components: {
    EventList,
    CalendarComponent,
    EventDetails,
  },
  methods: {
    updateSelectedDate(data) {
      this.selectedDate = data.date;
      this.sessionList = data.sessions;
      console.log(this.sessionList);
      // console.log("Selecting Date", this.selectedDate, date);
    },
    changeMonth(data) {
      console.log("CHANGING MONTH", data);
      const days = data[0].viewDays;
      const date = new Date(days[Math.floor(Object.keys(days).length / 3)].id);
      this.calendarDate = {
        month: date.getMonth(),
        year: date.getFullYear(),
      };
      // this.getEventList(date);
    },
    getEventList(dateRef) {
      console.log("getEventList", dateRef, this.viewedMonths);
      const codedDate = this.codeDate(dateRef);
      if (this.viewedMonths.find((month) => month === codedDate)) {
        return;
      } else {
        this.viewedMonths.push(codedDate);
      }

      const path = process.env.VUE_APP_BACKEND_URL + "/session";
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };
      const parameters = {
        date: dateRef,
      };

      axios
        .put(path, null, { params: parameters, headers: config.headers })
        .then((response) => {
          console.log("Did that work? xd", response.data);
          const mappedResponse = response.data.map((event) => {
            event.time = new Date(event.date).toLocaleTimeString();
            event.date = new Date(event.date).toLocaleDateString();
            return event;
          });
          this.calendarAttrs = [...this.calendarAttrs, ...mappedResponse];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    codeDate(date) {
      return `${date.getFullYear()}-${date.getMonth()}`;
    },
  },
  // mounted() {
  //   this.getEventList(this.selectedDate);
  // },
};
</script>

<style>
.calendar-dashboard {
  display: flex;
}

.calendar-view,
.event-details,
.event-list {
  padding: 20px;
}

.calendar-view {
  flex: 0.6;
}

.event-details,
.event-list {
  flex: 0.2;
}
</style>
