<template>
  <div class="elementHeader">
    <h2>{{ data.name }}</h2>
  </div>
  <table class="elementBody">
    <tr>
      <td>Tipus:</td>
      <td>{{ data.type }}</td>
    </tr>
    <tr v-if="data.mail !== ''">
      <td>Correu:</td>
      <td>{{ data.mail }}</td>
    </tr>
    <tr v-if="data.phone !== null">
      <td>Nº Telefon:</td>
      <td>{{ data.phone }}</td>
    </tr>
  </table>
  <div class="elementFooter" v-if="data.description !== ''">
    Descripció: {{ data.description }}
  </div>
</template>

<script>
export default {
  name: 'EntitatElement',
  props: ['data']
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
  border-bottom: 4px solid #ccc;
  color: #333f;
  margin: 0 0 1rem 0;
}

.elementBody {
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}

tr > * {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.elementFooter {
  margin-top: 20px;
}
</style>
