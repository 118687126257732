<template>
  <!-- Nom Activitat -->
  <div>
    <label for="nomAct">Nom de l'activitat.* </label>
    <input type="text" id="nomAct" name="nomAct" placeholder="ex. vela" v-model="activity.name" required />
  </div>

  <!-- Nº Persones -->
  <div>
    <label for="numPax">Número d'asistents.* </label>
    <input type="number" id="numPax" name="numPax" min="1" max="100" v-model="activity.amount_people" required />
  </div>

  <!-- Data Inici -->
  <div>
    <label for="dataIni">Data d'inici.* </label>
    <input type="datetime-local" id="dataIni" name="dataIni" min="2022-01-01" max="2299-12-31"
      v-model="activity.start_date" required />
  </div>

  <!-- Data Final -->
  <div>
    <label for="dataFin">Data final. </label>
    <input type="datetime-local" id="dataFin" name="dataFin" :min="activity.start_date" max="2299-12-31"
      v-model="activity.end_date" :disabled="activity.start_date === null" />
  </div>

  <!-- Duració -->
  <div>
    <label for="duration">Duració de la sessió (h).* </label>
    <input type="number" id="dataFin" name="dataFin" min="0" v-model="sessionValues.duration" required />
  </div>

  <!-- Frequencia -->
  <div>
    <label for="freq">Freqüència. </label>
    <select name="freq" id="freq" v-model="activity.frequency"
      :disabled="activity.end_date === null || activity.end_date === ''" required>
      <option disabled value="">Selecciona la frequència</option>
      <option value="1">Diari</option>
      <option value="2">Semanal</option>
    </select>
  </div>

  <!-- Materials -->
  <div>
    <label for="material">Materials.* </label>
    <div style="
        padding: 5px;
        border: 1px solid black;
        border-radius: 5px;
        max-height: 400px;
        overflow-y: scroll;
      ">
      <div v-for="(materialType, i) in materials" v-bind:key="i" style="
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          margin-bottom: 15px;
        ">
        <label style="text-align: left">{{ materialType.name }}</label>
        <div v-for="(material, i) in materialType.material" v-bind:key="i" :style="{
          backgroundColor:
            materialsSelected[material.id]['amount'] > 0
              ? 'rgb(199 199 199 / 21%)'
              : 'transparent',
          borderRadius: '5px',
          border: '1px solid #c7c7c7',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px 10px',
          marginBottom: '5px',
        }">
          <label>
            {{ material.name }}
          </label>
          <input type="number" placeholder="Amount" v-model="materialsSelected[material.id]['amount']" style="
              width: 50px;
              margin-left: 10px;
              border-radius: 5px;
              border: 1px solid #c7c7c7;
              padding: 0px 5px;
            " min="0" />
        </div>
      </div>
    </div>
  </div>

  <!-- Client -->
  <div>
    <label for="client">Client.* </label>
    <select v-model="clientsSelected" name="client" id="client">
      <option v-for="(client, i) in clients" v-bind:key="i" :value="client">
        {{ client.name }}
      </option>
    </select>
  </div>

  <!-- Monitors -->
  <div>
    <label for="monitors">Monitors.*</label>
    <select v-model="monitorsSelected" name="monitor" id="monitors" multiple required>
      <option v-for="(monitor, i) in monitors" v-bind:key="i" :value="monitor">
        {{ monitor.name }}
      </option>
    </select>
  </div>

  <!-- Preu -->
  <div>
    <label for="preu">Preu.* </label>
    <input type="number" id="preu" name="preu" placeholder="Preu total de l'activitat" v-model="activity.price"
      required />
  </div>

  <!-- Estat de pagament -->
  <div>
    <label for="paymentStatus">Estat de pagament.* </label>
    <input type="text" id="paymentStatus" name="paymentStatus" placeholder="Estat del pagament"
      v-model="sessionValues.paymentStatus" required />
  </div>

  <!-- Descripció -->
  <div>
    <label for="activityDescription">Descripció. </label>
    <textarea type="text" id="activityDescription" name="activityDescription" placeholder="Descripció de l'activitat"
      v-model="activity.description"></textarea>
  </div>

  <!-- Previsualització de Sessions -->
  <div style="grid-column: 1/-1; align-items: center">
    <h1 style="font-size: 1.5rem">Previsualització de Sessions</h1>
    <button type="button" @click="generateSessionList()" class="PraePlanButton"
      style="width: fit-content; padding: 0px 20px; color: #fffffe">
      Genera
    </button>
    <table v-if="prevSessions.length > 0">
      <tr>
        <th>Data de la sessió</th>
        <th>Duració de la sessió (h)</th>
        <th>Nº Pax</th>
        <th>Materials</th>
        <th>Clients</th>
        <th>Monitors</th>
        <th>Preu</th>
      </tr>
      <tr v-for="(session, i) in prevSessions" v-bind:key="i">
        <!-- Data de sessió -->
        <td>
          <input type="datetime-local" v-model="session.date" required />
        </td>

        <!-- Duració de la sessió -->
        <td>
          <input type="number" v-model="session.duration" required />
        </td>

        <!-- Nº Pax -->
        <td>
          <input type="number" v-model="session.amount_people" required />
        </td>

        <!-- Materials -->
        <td>
          <div v-for="(material, i) in session.materials" v-bind:key="i" style="
              display: flex;
              flex-direction: row;
              padding: 10px;
              min-width: 200px;
              max-width: 500px;
            " :style="{
              borderBottom:
                session.materials.length > 1 &&
                  session.materials.length - 1 !== i
                  ? '1px solid #3334'
                  : 'none',
            }">
            <label style="width: 60%; text-align: left">{{ material.name }} {{ i }}</label>
            <input type="number" v-model="material.amount" placeholder="Amount" style="width: 40%; text-align: right" />
          </div>
        </td>

        <!-- Clients -->
        <td>
          <select v-model="session.entity" name="client" id="client" required>
            <option v-for="(client, i) in clients" v-bind:key="i" :value="client">
              {{ client.name }}
            </option>
          </select>
        </td>

        <!-- Monitors -->
        <td>
          <select v-model="session.staff" name="monitor" id="monitors" multiple required>
            <option v-for="(monitor, i) in monitors" v-bind:key="i" :value="monitor">
              {{ monitor.name }}
            </option>
          </select>
        </td>

        <!-- Preu -->
        <td>
          <input type="number" v-model="session.price" step=".01" required />€
        </td>
      </tr>
    </table>
    <!-- Rounded switch -->
    <div v-if="prevSessions.length > 0" style="display: flex; flex-direction: row; align-items: center">
      <label style="font-size: 1.5rem; margin-right: 20px">
        Confirmar sessions:
      </label>
      <label class="switch">
        <input type="checkbox" v-model="checked" />
        <span class="slider round"></span>
      </label>
      <label>
        {{ checked }}
      </label>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreaAct.vue",
  data() {
    return {
      activity: {
        name: "TEST",
        amount_people: 20,
        start_date: null,
        end_date: null,
        frequency: "1",
        price: 200,
        priceXsession: null,
        description: null,
      },
      sessionValues: {
        duration: 2,
        paymentStatus: "Pagat",
      },
      monitors: {},
      monitorsSelected: [],
      clients: {},
      clientsSelected: {},
      materials: {},
      materialsSelected: {},
      prevSessions: [],
      checked: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Activitats" });
    },
    creaElement() {
      if (!this.checked) {
        this.generateSessionList();
        alert("Comprueba primero que las sesiones estan bien generadas!");
        return;
      }

      this.activity.priceXsession =
        this.activity.price / this.prevSessions.length;
      console.log("Session preview:", this.prevSessions);
      this.activity.sessions = this.prevSessions;

      // Once we have all the sessions, we post them
      const path = process.env.VUE_APP_BACKEND_URL + "/activity";
      const parameters = this.activity;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };

      axios
        .post(path, parameters, config)
        .then((res) => {
          console.log("Activity created!!", res.data);
        })
        .catch((error) => {
          console.error(error);
          alert("Error: " + error.message);
        })
        .finally(() => {
          this.$router.push({ name: "Activitats" });
        });
    },
    getRelationalElements() {
      // We are going to gather all three relational elements: Material, Clients, and Staff.

      // Gathering the material
      let path = process.env.VUE_APP_BACKEND_URL + "/materialType";
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };

      axios
        .get(path, config)
        .then((res) => {
          console.log("Material gathered!!", res.data);
          this.materials = res.data;
          this.materials.map((materialType) => {
            materialType.material.map((material) => {
              this.materialsSelected[material.id] = {
                id_type: materialType.id,
                id: material.id,
                amount: 0,
                name: material.name,
              };
            });
          });
        })
        .catch((error) => {
          console.error(error);
          alert("Error: " + error.message);
        });

      // Gathering the clients
      path = process.env.VUE_APP_BACKEND_URL + "/entity";

      axios
        .get(path, config)
        .then((res) => {
          console.log("Entities gathered!!", res.data);
          this.clients = res.data;
        })
        .catch((error) => {
          console.error(error);
          alert("Error: " + error.message);
        });

      // Gathering the staff
      path = process.env.VUE_APP_BACKEND_URL + "/staff";

      axios
        .get(path, config)
        .then((res) => {
          console.log("Staff Gathered!!", res.data);
          this.monitors = res.data;
        })
        .catch((error) => {
          console.error(error);
          alert("Error: " + error.message);
        });
    },
    generateSessionList() {
      console.log("GENERATING LIST");
      this.prevSessions = [];
      let totalDays;

      // If it's not a puntual activity. First of all, we create the paramaters that we will use.
      if (this.activity.end_date) {
        totalDays = Math.ceil(
          Math.abs(
            new Date(this.activity.start_date) -
            new Date(this.activity.end_date)
          ) /
          (1000 * 60 * 60 * 24)
        );
      }
      var date = new Date(this.activity.start_date);

      // Assign the materials selected
      let materialsSelected = [];
      Object.values(this.materialsSelected)
        .filter((material) => material.amount > 0)
        .map((material) => {
          materialsSelected.push(material);
        });

      // We start by the frequency
      // Daily basis
      if (this.activity.frequency == 1) {
        totalDays++;

        for (let i = 0; i < totalDays; i++) {
          let session = {
            id_activity: 0,
            date: new Date(date.getTime() - date.getTimezoneOffset() * 60000)
              .toISOString()
              .slice(0, -1),
            duration: this.sessionValues.duration,
            amount_people: this.activity.amount_people,
            price: Math.round((this.activity.price / totalDays) * 100) / 100,
            description: this.activity.description,
            materials: structuredClone(materialsSelected),
            entity: structuredClone(this.clientsSelected),
            staff: structuredClone(this.monitorsSelected),
          };
          console.log("Pushing session!", session);
          this.prevSessions.push(session);
          date.setDate(date.getDate() + 1);
        }
      }

      // Weekly Basis
      else if (this.activity.frequency == 2) {
        totalDays = Math.floor(totalDays / 7 + 1);
        for (let i = 0; i < totalDays; i++) {
          let session = {
            date: structuredClone(date),
            duration: this.sessionValues.duration,
            amount_people: this.activity.amount_people,
            price: this.activity.preuTotal / totalDays,
            description: this.activity.description,
          };
          console.log(session);
          console.log("Pushing session!");
          this.prevSessions.push(session);
          date.setDate(date.getDate() + 7);
        }
      }
    },
    formatDate(date) {
      date = new Date(date);
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        " - " +
        date.getHours() +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    },
    formatElement(elements) {
      let result = "";
      elements.forEach((element) => {
        result += element.name + ", ";
      });

      return result.slice(0, -2);
    },
  },
  created() {
    this.getRelationalElements();
  },
  watch: {
    prevSessions: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        for (let i = 0; i < Object.keys(newValue).length; i++) {
          newValue[i].price = Math.round(newValue[i].price * 100) / 100;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

td {
  margin: 15px 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
