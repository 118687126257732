<template>
      <div class="elementHeader">
        <h2>{{ data.name }}</h2>
      </div>
      <table class="elementBody" v-if="data.material.length > 0">
        <tr>
          <th>Nom</th>
          <th>Quantitat</th>
        </tr>
        <tr
        v-for="(material, i) in data.material"
        v-bind:key="i">
        <td>{{  material.name }}</td>
        <td>{{ material.amount }}</td>
        
      </tr>
      </table>
      <div v-else>
        No hi ha materials emmagatzemats en aquest tipus de material. <br>
        Clica aqui per crear-ne!
      </div>
      <div class="elementFooter">
        Total: {{materialAmount}} | Disponible: {{ materialAmount }}
      </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    goToEdit() {
      this.$router.push({ name: 'EditaMaterial' })
    },
    goToMaterial() {
      this.$router.push({ name: 'VisualitzaMaterial', query: { id: this.data.id } })
    }
  },
  computed: {
    // a computed getter
    materialAmount() {
      let amount = 0
      for (let i = 0; i < this.data.material.length; i++) {
        amount += this.data.material[i].amount
      }
      return amount
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
  border-bottom: 4px solid #ccc;
  color: #333f;
  margin: 0 0 1rem 0;
}

.elementBody {
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}

tr>* {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.elementFooter {
  margin-top: 20px;
}
</style>
