<template>
  <div>
    <label>Tipus.*</label>
    <input type="text" v-model="materialType.name" placeholder="Nom del tipus de material" required>
  </div>
  <div>
    <label>Descripció.</label>
    <textarea type="text" v-model="materialType.description" placeholder="Descripció del tipus de material"></textarea>
  </div>
  <div class="materialInput">
    <h1>
      Materials.
    </h1>
    <div id="materialContainer">
      <div class="materialList" v-for="(material, i) in materialType.material" v-bind:key="i">
        <input type="text" v-model="material.name" placeholder="Nom material" required>
        <input type="number" min="0" v-model="material.amount" placeholder="Quantitat" required>
        <button type="button" @click="removeMaterial(i)">Remove</button>
      </div>
      <div class="addMaterialButton">
        <button type="button" @click="addMaterial">+</button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      id: '',
      materialType: {
        name: null,
        description: null,
        material: [
          {
            id: 0,
            id_type: 0,
            name: null,
            amount: null,
            description: null
          }
        ]
      },
    };
  },
  name: 'CreaMaterial.vue',
  methods: {
    goBack() {
      this.$router.push({ name: 'VisualitzaMaterial', query: { id: this.id } });
    },
    addMaterial() {
      console.log("BEFORE", this.$store.state.buffer.viewMaterial)
      this.materialType.material.push({ id: 0, id_type: 0, name: null, amount: null, description: null });
      console.log("AFTER", this.$store.state.buffer.viewMaterial)
    },
    removeMaterial(i) {
      this.materialType.material.splice(i, i);
    },
    getElement() {
      // Gathering data from buffer in case there is
      let materialType = structuredClone(this.$store.state.buffer.viewMaterial)
      if (Object.keys(materialType).length > 0 && materialType.id == this.id) {
        this.materialType = materialType;
        console.log(materialType)
        for (let i = 0; i < this.materialType.material.length; i++) {
          this.materialAmount += this.materialType.material[i].amount;
        }
        return;
      }
      const path = process.env.VUE_APP_BACKEND_URL + '/materialType/' + this.id;
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      };
      axios.get(path, config)
        .then((resType) => {
          // We add the new material types to the list.
          this.materialType = resType.data;
          for (let i = 0; i < this.materialType.material.length; i++) {
            this.materialAmount += this.materialType.material[i].amount;
          }
          this.$store.dispatch('setViewMaterial', this.materialType)
        })
        .catch((error) => {
          console.error(error);
          alert('MaterialType!\nHi ha hagut un error: ' + error.message);
        }).finally(() => {
          // document.querySelector('#signInButton').disabled = false
        });
    },
    editaElement() {
      console.log('MaterialType:', this.mTypeName, this.mTypeDescription);
      const path = process.env.VUE_APP_BACKEND_URL + '/materialType/' + this.id;
      const parameters = {
        'id': this.id,
        'name': this.materialType.name,
        'description': this.materialType.description,
        'material': this.materialType.material
      }
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      }

      axios.put(path, parameters, config)
        .then((res) => {
          console.log('MaterialType edited!!', res.data);
          this.$store.dispatch('setViewMaterial', this.materialType)
        })
        .catch((error) => {
          console.error(error);
          alert('MaterialType. Error: ' + error.message);
        })
        .finally(() => {
          this.$router.push({ name: 'Materials' });
          // document.querySelector('#signInButton').disabled = false
        });
    },
    eliminaElement() {
      const path = process.env.VUE_APP_BACKEND_URL + '/materialType/' + this.id;
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
      };
      axios.delete(path, config)
        .then((res) => {
          // We add the new material types to the list.
          console.log(res.data)
          this.$router.push({ name: 'Materials' })
        })
        .catch((error) => {
          console.error(error);
          alert('Material!\nHi ha hagut un error: ' + error.message);
        })
    }
  },
  created() {
    this.id = this.$route.query.id;
  }
}
</script>

<style scoped>
.materialInput {
  margin-top: 20px;
  flex-direction: column;
  grid-column: 1/5;
}

.materialInput>h1 {
  font-size: 2rem;
}

.materialList>* {
  padding: 6px;
  margin-left: 3px;
  margin-bottom: 7px;
  border: 1px solid black;
  border-radius: 10px;
}

.materialList>button {
  margin-left: 20px;
}

.materialList>button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px;
  transform: translateY(-2px);
}

.materialList>button:active {
  box-shadow: inset rgba(45, 35, 66, 0.4) 0 1px 3px;
  transform: translateY(1px);
}

.addMaterialButton {
  border: 1px solid black;
  border-radius: 100px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
}

.addMaterialButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px;
  transform: translateY(-2px);
}

.addMaterialButton:active {
  box-shadow: inset rgba(45, 35, 66, 0.4) 0 1px 3px;
  transform: translateY(1px);
}
</style>