<template>
    <div id="gridContainer">
        <div>
            <label>Nom de l'activitat.</label>
            <span> {{ activity.name }}</span>
        </div>
        <div>
            <label>Número d'asistents.</label>
            <span> {{ activity.amount_people }} </span>
        </div>
        <div>
            <label>Data d'inici.</label>
            <span> {{ formatDate(activity.start_date) }} </span>
        </div>
        <div>
            <label>Data final. </label>
            <span> {{ formatDate(activity.end_date) }} </span>
        </div>
        <div>
            <label>Materials.</label>
            <span> Work in progress... </span>
        </div>
        <div>
            <label>Monitors.</label>
            <span> Work in progress...</span>
        </div>
        <div>
            <label>Client.</label>
            <span> Work in progress.. </span>
        </div>
        <div>
            <label>Preu total.</label>
            <span> {{ activity.price }}€ </span>
        </div>
        <div>
            <label>Estat de pagament.</label>
            <span> {{ activity.paymentStatus }} </span>
        </div>
        <div>
            <label for="activityDescription">Descripció. </label>
            <span> {{ activity.description }} </span>
        </div>
    </div>
    <div id="sessionContainer">
        <h1>
            Sessions.
        </h1>
        <div id="sessionList">
            <div class="sessionElement" v-for="(session, i) in activity.sessions" v-bind:key="i">
                <span>Fecha: {{ formatDate(session.date) }}</span>
                <span>Duración: {{ session.duration }}h</span>
                <span>Cantidad de personas: {{ session.amount_people }}</span>
                <span>Precio: {{ session.price }}€</span>
                <span v-if="session.description !== null">Descripción: {{ session.description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'VisualitzaActivitat.vue',
    data() {
        return {
            activity: {}
        }
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'Activitats' })
        },
        goToEdit() {
            this.$router.push({ name: 'EditaActivitat', query: { id: this.id } })
        },
        getElement() {
            // Gathering data from buffer in case there is
            let activity = this.$store.state.buffer.viewActivitat
            if (Object.keys(activity).length > 0 && activity.id == this.id) {
                console.log(activity);
                this.activity = activity;
                this.$emit('setName', this.activity.name)
                return;
            }
            const path = process.env.VUE_APP_BACKEND_URL + '/activity/' + this.id;
            const config = {
                headers: { Authorization: `Bearer ${this.$store.state.user.token}` }
            };
            axios.get(path, config)
                .then((res) => {
                    // We add the new material types to the list.
                    this.activity = res.data;
                    this.$store.dispatch('setViewActivitat', this.activity)
                    this.$emit('setName', this.activity.name)
                    console.log(this.activity);
                })
                .catch((error) => {
                    console.error(error);
                    alert('Activitat!\nHi ha hagut un error: ' + error.message);
                }).finally(() => {
                    // document.querySelector('#signInButton').disabled = false
                });
        },
        formatDate(date) {
            console.log(date)
            date = new Date(date)
            console.log(date)
            console.log(date.getHours(), date.getMinutes())
            return date.getDate() + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + ' - ' + date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0');
        }
    },
    created() {
        this.id = this.$route.query.id;
        this.getElement;
    },
}
</script>


<style scoped>
#gridContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

#gridContainer>div {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

#gridContainer>div>* {
    padding: 2px;
}

#gridContainer>div>label {
    margin-right: 5px;
    white-space: nowrap;
    text-align: left;
}

#gridContainer>div>span {
    width: 100%;
    border: 1px solid rgba(110, 110, 110, 0.171);
    border-radius: 5px;
    height: 2rem;
    font-size: 1rem;
}

#sessionContainer {
    overflow-y: auto;
}

#sessionContainer>h1 {
    font-size: 1.5rem;
}

#sessionList {
    overflow-y: auto;
    max-height: 40vh;
    padding: 20px;
}

.sessionElement {
    background-color: #d8d8d8;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
}
</style>