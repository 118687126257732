<template>
  <div>
    <label>Nom.*</label>
    <input v-model="empleat.name" placeholder="Nom de l'empleat" required />
  </div>
  <div>
    <label>Dni.*</label>
    <input v-model="empleat.dni" placeholder="DNI de l'empleat" required />
  </div>
  <div>
    <label>SS.*</label>
    <input v-model="empleat.socialSecurity" placeholder="Numero de la seguretat social" required />
  </div>
  <div>
    <label>Tipus de contracte.*</label>
    <select v-model="empleat.contractType" required>
      <option disabled value="">Selecciona el tipus</option>
      <option v-for="(staffType, i) in staffTypes" v-bind:key="i" :value="i">
        {{ staffType }}
      </option>
    </select>
  </div>
  <div>
    <label>Preu Hora.*</label>
    <input v-model="empleat.priceHour" placeholder="Preu Hora de l'empleat" required />
  </div>
  <div>
    <label>Preu Hora Extra.</label>
    <input v-model="empleat.priceHourExtra" placeholder="Preu Hora Extra de l'empleat" />
  </div>
  <div>
    <label> Dies disponibles </label>
    <select v-model="empleat.availabilityDays" multiple name="dies">
      <option value="1">dilluns</option>
      <option value="2">dimarts</option>
      <option value="3">dimecres</option>
      <option value="4">dijous</option>
      <option value="5">divendres</option>
      <option value="6">dissabte</option>
      <option value="0">diumenge</option>
    </select>
  </div>
  <div>
    <label> Horari </label>
    <label>
      Inici
      <input v-model="empleat.availabilityInici" type="time" />
    </label>
    <label>
      Final
      <input v-model="empleat.availabilityFinal" type="time" />
    </label>
  </div>
  <div class="description">
    <label>Descripció.</label>
    <textarea v-model="empleat.description" placeholder="Descripció de l'empleat" rows="4" cols="50"></textarea>
  </div>
</template>

<script>
import { StaffTypes } from '@/main_components/generic_components/gloablTypes';
import axios from 'axios';

export default {
  name: 'CreaEmpleat.vue',
  data() {
    return {
      empleat: {
        name: '',
        dni: null,
        socialSecurity: null,
        contractType: '',
        priceHour: null,
        priceHourExtra: null,
        description: null,
        availabilityDays: null,
        availabilityInici: null,
        availabilityFinal: null,
      },
      staffTypes: StaffTypes,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Empleats' });
    },
    creaElement() {
      console.log('Staff:', this.empleat);

      const path = process.env.VUE_APP_BACKEND_URL + '/staff';
      const parameters = this.empleat;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };

      axios
        .post(path, parameters, config)
        .then((res) => {
          console.log('Staff created!!', res.data, res.data.availabilityDays);
          console.log('hola?');
          console.log('dispo', res.data.availabilityDays);
        })
        .catch((error) => {
          console.error(error);
          alert('Error: ' + error.message);
        })
        .finally(() => {
          this.$router.push({ name: 'Empleats' });
          // document.querySelector('#signInButton').disabled = false
        });
    },
  },
};
</script>

<style scoped>
.description {
  margin-top: 20px;
}

.description>* {
  padding-right: 20px;
  display: flex;
}

.description>textarea {
  min-height: 1rem;
  resize: auto;
}
</style>
