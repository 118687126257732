<template>
  <div style="
      width: 100%;
      height: 100%;
      max-height: 50vh;
      border: 1px solid #333;
      border-radius: 10px;
      padding: 10px;
    ">
    <div>
      <h2>Eventos de hoy</h2>
      <ul v-if="todayEvents.length > 0">
        <li v-for="(session, i) in todayEvents" :key="i" class="event-item">
          {{ session.time.split(":")[0] }}:{{ session.time.split(":")[1] }} |
          {{ session.activity_name }}
        </li>
      </ul>
      <span v-else> No hay eventos para hoy :) </span>
    </div>

    <div>
      <h2>Próximos eventos</h2>
      <ul v-if="upcomingEvents.length > 0">
        <li v-for="(session, i) in upcomingEvents" :key="i" class="event-item">
          {{ session.date.split("/")[0] }}/{{ session.date.split("/")[1] }} |
          {{ session.time.split(":")[0] }}:{{ session.time.split(":")[1] }} |
          {{ session.activity_name }}
        </li>
      </ul>
      <span v-else> No hay eventos futuros! A por una cerveza!!! </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { parse } from "date-fns";
import { es } from "date-fns/locale";

export default {
  data() {
    return {
      eventList: [],
    };
  },
  computed: {
    todayEvents() {
      const today = new Date().toLocaleDateString();
      return this.eventList
        .filter((event) => {
          return event.date === today;
        })
        .sort((a, b) => {
          const timeA = a.time.split(":");
          const timeB = b.time.split(":");
          const hourA = parseInt(timeA[0]);
          const minuteA = parseInt(timeA[1]);
          const secondA = parseInt(timeA[2]);
          const hourB = parseInt(timeB[0]);
          const minuteB = parseInt(timeB[1]);
          const secondB = parseInt(timeB[2]);

          if (hourA !== hourB) {
            return hourA - hourB;
          } else if (minuteA !== minuteB) {
            return minuteA - minuteB;
          } else {
            return secondA - secondB;
          }
        });
    },
    upcomingEvents() {
      const today = new Date();
      const twoWeeksFromNow = new Date();
      twoWeeksFromNow.setDate(today.getDate() + 14);
      console.log("PREV FILTER AND SORT:", this.eventList);
      const filteredEventList = this.eventList.filter((event) => {
        let date = parse(event.date, "dd/MM/yyyy", new Date(), {
          locale: es,
        });
        return date > today && date <= twoWeeksFromNow;
      });
      console.log("POST FILTER:", filteredEventList);
      return filteredEventList.sort((a, b) => {
        return this.sortByDateAndTime(a, b);
      });
    },
  },
  methods: {
    sortByDateAndTime(a, b) {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA < dateB) {
        console.log("Right bigger:", a.date, b.date);
        return -1;
      } else if (dateA > dateB) {
        console.log("Left bigger:", a.date, b.date);
        return 1;
      } else {
        console.log("Same date:", a.date, b.date);
        const timeA = a.time.split(":").map(Number);
        const timeB = b.time.split(":").map(Number);

        for (let i = 0; i < timeA.length; i++) {
          if (timeA[i] < timeB[i]) {
            return -1;
          } else if (timeA[i] > timeB[i]) {
            return 1;
          }
        }

        return 0; // The dates and times are equal
      }
    },
    getEventList(dateRef) {
      const path = process.env.VUE_APP_BACKEND_URL + "/session";
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        },
      };
      const parameters = {
        date: dateRef,
      };

      axios
        .put(path, null, { params: parameters, headers: config.headers })
        .then((response) => {
          const mappedResponse = response.data.map((event) => {
            event.time = new Date(event.date).toLocaleTimeString();
            event.date = new Date(event.date).toLocaleDateString();
            return event;
          });
          this.eventList = [...this.eventList, ...mappedResponse];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getEventList(new Date());
    this.getEventList(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1, 12, 0, 0)
    );
  },
};
</script>

<style scoped>
.event-item {
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #3334;
}

ul {
  list-style-type: none;
}

div::-webkit-scrollbar {
  display: none;
}

div {
  overflow: auto;

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>

<!-- let a_date = parse(a.date, "dd/MM/yyyy", new Date(), {
  locale: es,
});
let b_date = parse(b.date, "dd/MM/yyyy", new Date(), {
  locale: es,
});
if (a_date !== b_date) {
  console.log(a.date, b.date);
  return a.time - b.time;
} else {
  console.log(a.time, b.time);
  const timeA = a.time.split(":");
  const timeB = b.time.split(":");
  const hourA = parseInt(timeA[0]);
  const minuteA = parseInt(timeA[1]);
  const secondA = parseInt(timeA[2]);
  const hourB = parseInt(timeB[0]);
  const minuteB = parseInt(timeB[1]);
  const secondB = parseInt(timeB[2]);
  if (hourA !== hourB) {
    return hourA - hourB;
  } else if (minuteA !== minuteB) {
    return minuteA - minuteB;
  } else {
    return secondA - secondB;
  } -->
