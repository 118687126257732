import { createStore } from "vuex";
import UserModule from './user.module.js';
import BufferModule from './buffer.module.js';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    resetAll(context) {
      context.dispatch('resetBuffer')
      context.dispatch('resetUser')
    }
  },
  modules: {
    user: UserModule,
    buffer: BufferModule
  },
  plugins: [createPersistedState()]
});
