<template>
    <PraePlanHeader :praePlanLogo="true" :title="listType">
    </PraePlanHeader>
    <main>
        <button @click="refreshList">
            Refresh
        </button>
        <article>
            <div v-if="loading">
                LOADING...
            </div>
            <div v-else>
                <div v-if="llista_items.length === 0" class="noElement">
                    <span>No hi ha cap {{ listType }} emmagatzemat.</span>
                    <button class="PraePlanButton" @click="goToNewElement">Crea'n un!</button>
                </div>
                <ul>
                    <li v-for="(item, i) in llista_items" v-bind:key="i">
                        <button class="elementContainer" @click="goToElement(item.id)">
                            <component :is="listType + 'Element'" :data=item>
                            </component>
                        </button>
                    </li>
                </ul>
            </div>
        </article>
    </main>
    <footer>
        <button class="PraePlanButton" @click="goToHome">&#60;</button>
        <button class="PraePlanButton" @click="goToNewElement">+</button>
    </footer>
</template>

<script>
import ActivitatElement from '@/main_components/activitats_components/ActivitatElement.vue'
import EmpleatElement from '@/main_components/empleats_components/EmpleatElement.vue'
import EntitatElement from '@/main_components/entitats_components/EntitatElement.vue'
import CreaTemplate from '@/main_components/generic_components/CreaTemplate.vue'
import PraePlanHeader from '@/main_components/generic_components/PraePlanHeader.vue'
import { ListType } from '@/main_components/generic_components/gloablTypes'
import MaterialElement from '@/main_components/materials_components/MaterialElement.vue'
import axios from 'axios'

export default {
    props: ['listType',],
    name: 'ItemList.vue',
    data() {
        return {
            llista_items: [],
            loading: true
        }
    },
    methods: {
        goToHome() {
            this.$router.push({ name: 'Home' })
        },
        goToNewElement() {
            this.$router.push({ name: 'Crea' + this.listType })
        },
        goToElement(itemId) {
            console.log(itemId)
            this.$router.push({ name: 'Visualitza' + this.listType, query: { id: itemId } })
        },
        getElements() {
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.user.token}`;
            console.log("Getting " + this.listType + " List")

            let llista_items = []

            switch (this.listType) {
                case ListType.ACTIVITAT: {
                    llista_items = this.$store.state.buffer.activitatList
                    break;
                }
                case ListType.MATERIAL: {
                    llista_items = this.$store.state.buffer.materialList
                    break;
                }
                case ListType.EMPLEAT: {
                    llista_items = this.$store.state.buffer.empleatList
                    break;
                }
                case ListType.ENTITAT: {
                    llista_items = this.$store.state.buffer.entitatList
                }
            }

            console.log("Buffer " + this.listType + " List: ", llista_items)

            if (llista_items.length > 0) {
                this.llista_items = llista_items;
                this.llista_items.sort((a, b) => (a.id > b.id) ? 1 : -1)
                this.loading = false;
                return;
            }

            console.log(this.listType + "List not enough")

            let tempPath = ""

            switch (this.listType) {
                case ListType.ACTIVITAT: {
                    tempPath = process.env.VUE_APP_BACKEND_URL + '/activity'
                    break;
                }
                case ListType.MATERIAL: {
                    tempPath = process.env.VUE_APP_BACKEND_URL + '/materialType'
                    break;
                }
                case ListType.EMPLEAT: {
                    tempPath = process.env.VUE_APP_BACKEND_URL + '/staff'
                    break;
                }
                case ListType.ENTITAT: {
                    tempPath = process.env.VUE_APP_BACKEND_URL + '/entity'
                }
            }

            const path = tempPath
            console.log(path);
            axios.get(path)
                .then((resType) => {
                    console.log(this.listType + ' list obtained successfully:')
                    console.log(resType.data)
                    // We add the new material types to the list.
                    this.llista_items = resType.data
                    this.$store.dispatch('set' + this.listType + 's', resType.data)
                    this.loading = false
                })
                .catch((error) => {
                    console.error(error)
                    alert(this.listType + '!\nHi ha hagut un error: ' + error.message)
                }).finally(() => {
                    // document.querySelector('#signInButton').disabled = false
                })
        },
        refreshList() {
            this.llista_items = []
            this.$store.dispatch('set' + this.listType + 's', [])
            this.loading = true
            this.getElements()
        }
    },
    components: {
        MaterialElement,
        ActivitatElement,
        EntitatElement,
        EmpleatElement,
        PraePlanHeader,
        CreaTemplate
    },
    created() {
        this.getElements()
    }
}
</script>

<style scoped>
main {
    padding-bottom: 4rem;
    background-color: #fffffe;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

main>button {
    position: absolute;
    left: 5%;
    top: 20px;
    background-color: bisque;
    border-radius: 10px;
    padding: 5px;
}

article {
    background-color: #f5f5f5;
    overflow-y: auto;
    max-height: 100%;
    height: 100vh;
    padding-bottom: 4rem;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer {
    justify-content: space-around;
}

.PraePlanButton {
    font-size: 1.5rem;
}

li {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin: 1rem auto;
    border-radius: 10px;

    text-align: center;
    width: 90%;
    max-width: 40rem;
}

li:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.514);
}

li:active {
    transform: translateY(1px);
}

.elementContainer {
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: #d8d8d8;
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
}

.noElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;
    margin-top: 4rem;
}

.noElement>button {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: #fffffe;
}
</style>
@/main_components/generic_components/gloablTypes