<template>
  <div class="elementHeader">
    <h2>{{ data.name }}</h2>
  </div>
  <table class="elementBody">
    <tr>
      <td>Data Inici:</td>
      <td>{{ formatDate(data.start_date) }}</td>
      <td>Data Fin:</td>
      <td>{{ formatDate(data.end_date) }}</td>
    </tr>
    <tr>
      <td>Nº Persones:</td>
      <td>{{ data.amount_people }}</td>
    </tr>
    <tr>
      <td>Preu x sessió:</td>
      <td>{{ data.priceXsession }}€</td>
      <td>Preu total:</td>
      <td>{{ data.price }}€</td>
    </tr>
  </table>
  <div class="elementFooter" v-if="data.description !== ''">
    Descripció: {{ data.description }}
  </div>
</template>

<script>
export default {
  name: 'ActivityElement',
  props: ['data'],
  methods: {
    formatDate(date) {
      date = new Date(date)
      return date.getDate() + '/' + (date.getMonth() + 1).toString().padStart(2, '0') // + ' - ' + date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0');
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
  border-bottom: 4px solid #ccc;
  color: #333f;
  margin: 0 0 1rem 0;
}

.elementBody {
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}

tr > * {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.elementFooter {
  margin-top: 20px;
}
</style>
