<template>
    <PraePlanHeader :praePlanLogo="true" :title="'Creant ' + listTypes[type]">
    </PraePlanHeader>
    <form @submit.prevent="creaElement()" id="my-form">
        <div id="gridContainer">
            <component :is="'Crea' + listTypes[type]" ref="creaElement">
            </component>
        </div>
    </form>
    <footer>
        <button class="PraePlanButton" @click="$refs.creaElement.goBack()">&#60;</button>
        <button type="submit" form="my-form" class="PraePlanButton">Confirmar</button>
    </footer>
</template>

<script>
import PraePlanHeader from '@/main_components/generic_components/PraePlanHeader.vue'
import CreaMaterial from '@/main_components/materials_components/CreaMaterial.vue'
import CreaActivitat from '@/main_components/activitats_components/CreaActivitat.vue'
import CreaEntitat from '@/main_components/entitats_components/CreaEntitat.vue'
import CreaEmpleat from '@/main_components/empleats_components/CreaEmpleat.vue'
import { ListType } from '@/main_components/generic_components/gloablTypes';


export default {
    data() {
        return {
            type: null,
            listTypes: ListType
        }
    },
    methods: {
        creaElement() {
            this.$refs.creaElement.creaElement()
        }
    },
    created() {
        this.type = this.$route.name.substr(4).toUpperCase()
    },
    components: {
        CreaMaterial,
        CreaActivitat,
        CreaEntitat,
        CreaEmpleat,
        PraePlanHeader
    }
}

</script>


<style>

form {
  padding: 0px 15%;
  overflow-y: auto;
  padding-bottom: 150px;
  max-height: 100vh;
}

#gridContainer {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

#gridContainer>div {
  padding: 0.5rem;
  display: flex;
  align-self: flex-start;
}

#gridContainer>div>* {
  padding: 2px;
}

#gridContainer>div>label {
  margin-right: 5px;
  white-space: nowrap;
}

#gridContainer>div>input, #gridContainer>div>textarea, #gridContainer>div>select {
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
}

</style>