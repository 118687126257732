export const ListType = {
    MATERIAL: 'Material',
    ENTITAT: 'Entitat',
    EMPLEAT: 'Empleat',
    ACTIVITAT: 'Activitat',
}

export const UserTypes = {
    ADMIN: 'ADMIN',
    CLIENT: 'CLIENT',
    CLIENTZONE: 'CLIENTZONE',
    STAFF: 'STAFF'
  }

export const StaffTypes = {
    1: 'Temporal',
    2: 'Fixe'
}